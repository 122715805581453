// Imports libraries
import React from "react";

// Creates custom columns
export default
function Column
(props)
{

    const classes = `column-${props.size} ${props.side}`; 

    return (
        <div class={classes}>
            {props.children}
        </div>
    );

}