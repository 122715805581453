// Imports libraries
import React from "react";

// Creates custom columns
export default
function Card
(props)
{

    const classes = `card ${props.theme}`;
    
    return (
        <div class={classes}>
            {props.children}
        </div>
    );

}