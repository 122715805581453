// Imports libraries
import React from "react";

// Imports components
import Row from "../atoms/row";
import Column from "../atoms/column";

// Creates custom columns
export default
function Aspect
(props)
{

    return (
        <Column size="50" side={props.side}>
            <img class="icon left" src={props.icon} alt={props.alt} draggable={false} />
            <Row>
                <h3>{props.heading}</h3>
                <p class="emphasis">{props.summary}</p>
                <p>{props.description}</p>
            </Row>
            {props.children}
        </Column>
    );

}