import {h2, h3, p, s, img, sizes, colours} from "../report";

export default
async function Contents (doc, participants) {
    doc.addPage("a4", "l");

    h2(doc, "Contents", {offsetX: 20, offsetY: 7.5});
    img(doc, "/assets/images/icons/brands/performance-edge.png", 15, 10, 15, 15);

    doc.setDrawColor(colours.auxiliary);
    doc.setLineWidth(2);
    doc.line(sizes.marginX, sizes.marginY+15, doc.internal.pageSize.width-sizes.marginX, sizes.marginY+15);

    h3(doc, "Report Layout", {offsetX: 0, offsetY: 30});
    let offset = 0;
    let n = "";

    let heading = "Introduction";
    let description = "An overview of the 'Creating Team Edge Profile' (CTEP) diagnostic instrument.";
    p(doc, heading, {offsetX: 0, offsetY: 45+offset});
    s(doc, description, {offsetX: 0, offsetY: 52.5+offset});
    offset += 15 + 5 * 1;

    heading = "High Level Overview";
    description = "This provides an overview of your team's results. The questions used in the questionnaire describe the key attributes of 'great team working' and therefore a strong 'favourable' score would indicate those areas where the team are working and functioning well together and 'unfavourable' ratings would signpost potential areas for continued development.";
    p(doc, heading, {offsetX: 0, offsetY: 45+offset});
    s(doc, description, {offsetX: 0, offsetY: 52.5+offset});
    offset += 15 + 5 * 3;

    heading = "Detailed Results";
    description = "This section allows you to explore each element of the team questionnaire in detail and analyse the specific responses for each question overall and per participant category. It merges participants' ratings together to provide summative scores and show exactly how well the team ranks up to the 'Creating the Team Edge' model.";
    p(doc, heading, {offsetX: 0, offsetY: 45+offset});
    s(doc, description, {offsetX: 0, offsetY: 52.5+offset});
    offset += 15 + 5 * 3;

    heading = "Top, Middle, and Bottom";
    description = "These tables show the seven highest, middle, and lowest scoring items for the team. These scores will help the team to identify areas of strength to be celebrated, any areas of uncertainty, and further development opportunities.";
    p(doc, heading, {offsetX: 0, offsetY: 45+offset});
    s(doc, description, {offsetX: 0, offsetY: 52.5+offset});
    offset += 15 + 5 * 2;

    heading = "Summary Comments";
    description = "Free text comments that capture generic strengths, opportunities for further development, and ideas for action.";
    p(doc, heading, {offsetX: 0, offsetY: 45+offset});
    s(doc, description, {offsetX: 0, offsetY: 52.5+offset});

    doc.addPage("a4", "l");

    h2(doc, "Contributors", {offsetX: 20, offsetY: 7.5});
    img(doc, "/assets/images/icons/brands/performance-edge.png", 15, 10, 15, 15);

    doc.setDrawColor(colours.auxiliary);
    doc.setLineWidth(2);
    doc.line(sizes.marginX, sizes.marginY+15, doc.internal.pageSize.width-sizes.marginX, sizes.marginY+15)

    h3(doc, "Participant Categories", {offsetX: 0, offsetY: 30});
    offset = 0;

    let num = participants.numTeamMembers;
    heading = "Team Member";
    description = "A member or leader of the team.";
    p(doc, heading, {offsetX: 0, offsetY: 40+offset});
    s(doc, `(${num})`, {offsetX: sizes.pageWidth/5+5, offsetY: 40+offset});
    s(doc, description, {offsetX: sizes.pageWidth/5+25, offsetY: 40+offset});
    offset += 10;

    num = participants.numDirectReports;
    heading = "Direct Report";
    description = "A colleague who reports directly to a team member.";
    p(doc, heading, {offsetX: 0, offsetY: 40+offset});
    s(doc, `(${num})`, {offsetX: sizes.pageWidth/5+5, offsetY: 40+offset});
    s(doc, description, {offsetX: sizes.pageWidth/5+25, offsetY: 40+offset});
    offset += 10;

    num = participants.numTeamCommissioners;
    heading = "Team Commissioner";
    description = "A member of the organisation who sets the overall direction and signs off the final budget for the team.";
    p(doc, heading, {offsetX: 0, offsetY: 40+offset});
    s(doc, `(${num})`, {offsetX: sizes.pageWidth/5+5, offsetY: 40+offset});
    s(doc, description, {offsetX: sizes.pageWidth/5+25, offsetY: 40+offset});
    offset += 10;

    num = participants.numStakeholders;
    heading = "Stakeholder";
    description = "An internal or external client, customer, or peer of the team.";
    p(doc, heading, {offsetX: 0, offsetY: 40+offset});
    s(doc, `(${num})`, {offsetX: sizes.pageWidth/5+5, offsetY: 40+offset});
    s(doc, description, {offsetX: sizes.pageWidth/5+25, offsetY: 40+offset});

}