// Imports libraries
import React from "react";

// Creates custom columns
export default
function Spacer
(props)
{

    return (
        <React.Fragment>
            <p class="spacer"></p>
            <p class="spacer"></p>
            <p class="spacer"></p>
        </React.Fragment>
    );

}