// Imports libraries
import React from "react";

// Imports components
import Row from "../atoms/row";
import Column from "../atoms/column";

// Creates custom columns
export default
function Banner
(props)
{   
    var curvature = "";
    if (props.isFooter !== "true") curvature = "curve";
    const classes = `banner ${curvature} ${props.theme}`;

    return (
        <Row><Column size="100" side="left">
            <div class={classes}>
                {props.children}
            </div>
        </Column></Row>
    );

}