// Imports libraries
import React from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { authentication as auth } from "../index";

// Imports pages
import Layout from "../view/templates/layout";
import Home from "../view/templates/home";
import Trial from "../view/templates/trial";
import Join from "../view/templates/join";
import Portal from "../view/templates/portal";
import Thanks from "../view/templates/thanks";
import Questionnaire from "../view/templates/questionnaire";
import Settings from "../view/templates/settings";
import NotFound from "../view/templates/notfound";
import { useAuthState } from "react-firebase-hooks/auth";

// Loads router
export default
function Router
()
{

    const [user] = useAuthState(auth);

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" basename="/" element={<Layout />}>
                    <Route index element={(!user) ? <Home /> : <Portal id={user.uid}/>} />
                    {!!user && <Route path="settings" element={<Settings />} />}
                    <Route path="join" element={<Join />} />
                    <Route path="mini360" element={<Trial />} />
                    <Route path="questionnaire" element={<Questionnaire />} />
                    <Route path="team360" element={<Questionnaire />} />
                    <Route path="thank-you" element={<Thanks />} />
                    <Route path="*" element={<NotFound />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );

}