import {h2, h3, p, s, k, img, sizes, colours} from "../report";

function highLevelBar(doc, title, data, offset) {

    let ratio;
    let size;
    let start = sizes.marginX;

    let favourable = 0;
    let neutral = 0;
    let unfavourable = 0;

    for (let datum of data) {
        if      (datum === 1) unfavourable++;
        else if (datum === 2) unfavourable++;
        else if (datum === 3) neutral++;
        else if (datum === 4) favourable++;
        else if (datum === 5) favourable++;
    }

    const total = favourable + neutral + unfavourable;

    s(doc, title, {offsetX: 0, offsetY: 30+offset});

    doc.setLineWidth(0);
    doc.setDrawColor(colours.secondary);

    ratio = favourable / total;
    size = sizes.pageWidth * ratio;
    doc.setFillColor(colours.favourable);
    doc.rect(start, sizes.marginY+32.5+offset, size, 6.0, "F");
    if (ratio >= 0.05) k(doc, `${Math.round(ratio*100)}%`, {offsetX: start-sizes.marginX+size/2, offsetY: 36.75+offset, sizeX: size, align: "center"});
    start = start + size;

    ratio = neutral / total;
    size = sizes.pageWidth * ratio;
    doc.setFillColor(colours.neutral);
    doc.rect(start, sizes.marginY+32.5+offset, size, 6, "F");
    if (ratio >= 0.05) k(doc, `${Math.round(ratio*100)}%`, {offsetX: start-sizes.marginX+size/2, offsetY: 36.75+offset, sizeX: size, align: "center"});
    start = start + size;

    ratio = unfavourable / total;
    size = sizes.pageWidth * ratio;
    doc.setFillColor(colours.unfavourable);
    doc.rect(start, sizes.marginY+32.5+offset, size, 6, "F");
    if (ratio >= 0.05) k(doc, `${Math.round(ratio*100)}%`, {offsetX: start-sizes.marginX+size/2, offsetY: 36.75+offset, sizeX: size, align: "center"});

    doc.setLineWidth(0.25);
    doc.rect(sizes.marginX, sizes.marginY+32.5+offset, sizes.pageWidth, 6, "S");

} 

export default
async function Overview (doc, participants) {
    let data;
    doc.addPage("a4", "l");

    h2(doc, "High Level Overview", {offsetX: 20, offsetY: 7.5});
    img(doc, "/assets/images/icons/brands/performance-edge.png", 15, 10, 15, 15)

    doc.setDrawColor(colours.auxiliary);
    doc.setLineWidth(2);
    doc.line(sizes.marginX, sizes.marginY+15, doc.internal.pageSize.width-sizes.marginX, sizes.marginY+15);

    let offset = 0;
    let allData = []
    
    offset = offset + 15;
    data = [];
    for (let question = 1; question <= 6; question++) {
        data = data.concat(
            participants.teamMembers[question-1],
            participants.directReports[question-1],
            participants.teamCommissioners[question-1],
            participants.stakeholders[question-1]
        );
    }
    allData = allData.concat(data);
    highLevelBar(doc, "Purpose", data, offset);

    offset = offset + 15;
    data = [];
    for (let question = 7; question <= 12; question++) {
        data = data.concat(
            participants.teamMembers[question-1],
            participants.directReports[question-1],
            participants.teamCommissioners[question-1],
            participants.stakeholders[question-1]
        );
    }
    allData = allData.concat(data);
    highLevelBar(doc, "Identity", data, offset);

    offset = offset + 15;
    data = [];
    for (let question = 13; question <= 18; question++) {
        data = data.concat(
            participants.teamMembers[question-1],
            participants.directReports[question-1],
            participants.teamCommissioners[question-1],
            participants.stakeholders[question-1]
        );
    }
    allData = allData.concat(data);
    highLevelBar(doc, "Values and Beliefs", data, offset);

    offset = offset + 15;
    data = [];
    for (let question = 19; question <= 24; question++) {
        data = data.concat(
            participants.teamMembers[question-1],
            participants.directReports[question-1],
            participants.teamCommissioners[question-1],
            participants.stakeholders[question-1]
        );
    }
    allData = allData.concat(data);
    highLevelBar(doc, "Awareness", data, offset);

    offset = offset + 15;
    data = [];
    for (let question = 25; question <= 30; question++) {
        data = data.concat(
            participants.teamMembers[question-1],
            participants.directReports[question-1],
            participants.teamCommissioners[question-1],
            participants.stakeholders[question-1]
        );
    }
    allData = allData.concat(data);
    highLevelBar(doc, "Relatedness", data, offset);

    offset = offset + 15;
    data = [];
    for (let question = 31; question <= 36; question++) {
        data = data.concat(
            participants.teamMembers[question-1],
            participants.directReports[question-1],
            participants.teamCommissioners[question-1],
            participants.stakeholders[question-1]
        );
    }
    allData = allData.concat(data);
    highLevelBar(doc, "Ways of Working", data, offset);

    offset = offset + 15;
    data = [];
    for (let question = 37; question <= 42; question++) {
        data = data.concat(
            participants.teamMembers[question-1],
            participants.directReports[question-1],
            participants.teamCommissioners[question-1],
            participants.stakeholders[question-1]
        );
    }
    allData = allData.concat(data);
    highLevelBar(doc, "Transformation", data, offset);

    highLevelBar(doc, "All Elements", allData, 0);

    // Key

    offset = 140;
    p(doc, "Key", {offsetX: 0, offsetY: 30+offset});

    doc.setLineWidth(0);

    doc.setFillColor(colours.favourable);
    doc.rect(sizes.marginX+sizes.pageWidth/3*0, sizes.marginY+32.5+offset, sizes.pageWidth/3, 6, "F");
    k(doc, "Favourable (Strongly Agree & Agree)", {offsetX: sizes.pageWidth/3*0+sizes.pageWidth/6, offsetY: 36.75+offset, sizeX: sizes.pageWidth/3, align: "center", colour: colours.primary})

    doc.setFillColor(colours.neutral);
    doc.rect(sizes.marginX+sizes.pageWidth/3*1, sizes.marginY+32.5+offset, sizes.pageWidth/3, 6, "F");
    k(doc, "Neutral (Undecided)", {offsetX: sizes.pageWidth/3*1+sizes.pageWidth/6, offsetY: 36.75+offset, sizeX: sizes.pageWidth/3, align: "center", colour: colours.primary})

    doc.setFillColor(colours.unfavourable);
    doc.rect(sizes.marginX+sizes.pageWidth/3*2, sizes.marginY+32.5+offset, sizes.pageWidth/3, 6, "F");
    k(doc, "Unfavourable (Strongly Disagree & Disagree)", {offsetX: sizes.pageWidth/3*2+sizes.pageWidth/6, offsetY: 36.75+offset, sizeX: sizes.pageWidth/3, align: "center", colour: colours.primary})

    doc.setLineWidth(0.25);
    doc.rect(sizes.marginX, sizes.marginY+32.5+offset, sizes.pageWidth, 6, "S");

}