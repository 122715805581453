// Imports libraries
import React from "react";
import { useState, useEffect } from "react";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import generateReport from "./report";
import { isMobile } from 'react-device-detect';
// Imports loacles
import locale_portal from "../locale/portal.json";

// Imports components
import Row from "../components/atoms/row";
import Column from "../components/atoms/column";
import Spacer from "../components/atoms/spacer";
import Top from "../components/organisms/top";
import Bottom from "../components/organisms/bottom";

import { loadStripe } from "@stripe/stripe-js";

// Imports models
import { authentication as auth } from "../../index";
import { database as db } from "../../index";
import { functions as fn } from "../../index";
import { httpsCallable } from "firebase/functions";
import { collection, doc, getDoc, getDocs, deleteDoc, setDoc, addDoc, updateDoc } from "firebase/firestore";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import readXlsxFile from 'read-excel-file';
import { isNullOrUndefined } from "url/util";
import { toast } from "react-hot-toast";

const CryptoJS = require("crypto-js");

const text_portal = JSON.parse(JSON.stringify(locale_portal));
const stripePromise = loadStripe("pk_live_51JhbvBHXZAeUxPGdtThBWIb709K78nsuOE97BjwW3pnSwVJY1MBCwQzocmhfHGmc5bIaBB3gFHcU9SkNhaYCN5aT0038MVwIJh")

function SelectCategory(props) {

    const [currentCategory, setCurrentCategory] = useState(props.category);

    const setCategory = (newCategory) => {
        const data = { "category": newCategory };
        const q = doc(collection(doc(collection(doc(collection(db, "Coaches"), props.coachid), "Questionnaires"), props.questionnaireid), "Participants"), props.participantid);
        toast.promise(updateDoc(q, data)
        .then(() => {
            setCurrentCategory(newCategory);
        }), {
            loading: `Updating the participant category for ${props.participantName}...`,
            success: `Successfully updated the participant category for ${props.participantName} to ${newCategory}.`,
            error: `We could not update the participant category for ${props.participantName}. Please check your internet connection and try again.`
        })
    }

    return <select value={currentCategory} onChange={(event) => setCategory(event.target.value)}>
        <option value="Team Member">Team Member</option>
        <option value="Direct Report">Direct Report</option>
        <option value="Team Commissioner">Team Commissioner</option>
        <option value="Stakeholder">Stakeholder</option>
    </select>;

}

function DisplayTable(props)
{

    const [participants, setParticipants] = useState({});
    const [id, setid] = useState(0);
    const [qID, setqID] = useState(0);

    const deleteParticipant = (pID, fullName) => {
        const confirmed = window.confirm("Do you want to permanently remove all data about this participant in this Team 360? This action is irreversible.");
        if (!confirmed)
            return;
        const participantLOC = doc(collection(doc(collection(doc(collection(db, `Coaches`), id), `Questionnaires`), qID), `Participants`), pID);
        toast.promise(deleteDoc(participantLOC)
        .then(() => {
            props.setTableKey(props.tableKey + 1);
            document.getElementById("SCROLL_TO").scrollIntoView({ top: 20, behavior: 'smooth' });
        }), {
            loading: `Deleting ${fullName} and their responses from this Team 360...`,
            success: `Successfully deleted ${fullName} and their responses from this Team 360`,
            error: `We were unable to delete ${fullName} and their responses from this Team 360. Please check your internet connection and try again.`
        })
    }

    useEffect(() => {

        setid(props.coachid);
        setqID(props.questionnaire.id);

        const gather = async () => {
            const pDOC = await getDocs(collection(doc(collection(doc(collection(db, "Coaches"), props.coachid), "Questionnaires"), props.questionnaire.id), "Participants"));
            setParticipants(pDOC);
            let numParticipants = 0;
            let numTeamMembers = 0;
            let totTeamMembers = 0;
            let numDirectReports = 0;
            let totDirectReports = 0;
            let numTeamCommissioners = 0;
            let totTeamCommissioners = 0;
            let numStakeholders = 0;
            let totStakeholders = 0;
            let totParticipants = 0;
            pDOC.forEach((pDAT, index) => {
                const participant = pDAT.data();
                numParticipants++;
                if (participant.category === "Team Member") {
                    numTeamMembers++;
                    for (let i = 1; i <= 42; i++) if (participant[`q${i}`] > 0) {
                        totTeamMembers++;
                        totParticipants++;
                    }
                } else if (participant.category === "Direct Report") {
                    numDirectReports++;
                    for (let i = 1; i <= 42; i++) if (participant[`q${i}`] > 0) {
                        totDirectReports++;
                        totParticipants++;
                    }
                } else if (participant.category === "Team Commissioner") {
                    numTeamCommissioners++;
                    for (let i = 1; i <= 42; i++) if (participant[`q${i}`] > 0) {
                        totTeamCommissioners++;
                        totParticipants++;
                    }
                } else if (participant.category === "Stakeholder") {
                    numStakeholders++;
                    for (let i = 1; i <= 42; i++) if (participant[`q${i}`] > 0) {
                        totStakeholders++;
                        totParticipants++;
                    }
                }
            });
            props.setStatistics({
                numParticipants: numParticipants,
                numTeamMembers: numTeamMembers,
                numDirectReports: numDirectReports,
                numTeamCommissioners: numTeamCommissioners,
                numStakeholders: numStakeholders,
                totTeamMembers: (!numTeamMembers) ? 0 : Math.round((100 * totTeamMembers) / (numTeamMembers * 42)),
                totDirectReports: (!numDirectReports) ? 0 : Math.round((100 * totDirectReports) / (numDirectReports * 42)),
                totTeamCommissioners: (!numTeamCommissioners) ? 0 : Math.round((100 * totTeamCommissioners) / (numTeamCommissioners * 42)),
                totStakeholders: (!numStakeholders) ? 0 : Math.round((100 * totStakeholders) / (numStakeholders * 42)),
                totParticipants: (!numParticipants) ? 0 : Math.round((100 * totParticipants) / (numParticipants * 42)),
            });
        }
        gather();
    }, [props.questionnaire.id, props.tableKey]);

    if (!participants.docs || participants.docs.length === 0)
        return(<div class="container"><br /><p class="container">{text_portal["team360"]["table"]["no_participants"]}</p><br /></div>);

    const ps = [];
    
    return (
        <React.Fragment>
        <table class="container">
            <thead>
                <tr>
                    <th>{text_portal["team360"]["table"]["full_name"]}</th>
                    <th>{text_portal["team360"]["table"]["email_address"]}</th>
                    <th>{text_portal["team360"]["table"]["participant_category"]}</th>
                    <th>{text_portal["team360"]["table"]["questionnaire_progress"]}</th>
                    <th>{text_portal["team360"]["table"]["personal_link"]}</th>
                    <th>{text_portal["team360"]["table"]["delete_participant"]}</th>
                </tr>
            </thead>
            <tbody>
                {participants.forEach((index) => {

                    const participant = index.data();
                    let progress;
                    let colour;

                    if (participant.q42 > 0) {
                        progress = "Completed";
                        colour = "completed";
                    } else if (participant.q1 > 0) {
                        progress = "Started but Incomplete";
                        colour = "started";
                    } else {
                        progress = "Not Started";
                        colour = "notstarted";
                    }

                    let sum = 0;
                    for (let i = 1; i <= 42; i++) {
                        sum += (participant[`q${i}`] > 0) ? 1 : 0;
                    }
                    let total = Math.round(sum / 42 * 100);

                    if (participant.q42 > 0 && total !== 100) {
                        progress = "Completed with Missing Data";
                        colour = "missingdata";
                    }

                    ps.push(
                        <tr>
                            <td>{participant.full_name}</td>
                            <td>{participant.email_address}</td>
                            <td> 
                                <SelectCategory
                                    category={participant.category}
                                    coachid={props.coachid}
                                    questionnaireid={props.questionnaire.id}
                                    participantid={index.id}
                                    participantName={participant.full_name} />
                            </td>
                            <td class={colour}>{progress}<br />({total}%)</td>
                            <td><CopyToClipboard onCopy={() => toast.success(`Successfully copied the peraonl link for ${participant.full_name}. Please only share this with the participant.`)} text={`https://creating-the-team-edge.web.app/team360?c=${id}&q=${qID}&p=${index.id}`}>
                                <button class="accent" id="copy_personal_link">{text_portal["team360"]["table"]["actions"]["copy_personal_link"]}</button>
                            </CopyToClipboard></td>
                            <td>
                                <button class="accent" id="delete_participant" onClick={() => deleteParticipant(index.id, participant.full_name)}>{text_portal["team360"]["table"]["actions"]["delete_participant"]}</button>
                            </td>
                        </tr>
                    );

                })}
                {ps}
            </tbody>
        </table>
        <Spacer />
        </React.Fragment>
    );
}

// Accesses about page
export default
function Portal
(props)
{

    const id = props.id;
    const link = new URL(document.URL);
    const params = link.searchParams;

    const [getStatistics, setStatistics] = useState({});

    const [getVoucher, setVoucher] = useState("");
    const [anonymous, setAnonymous] = useState("");
    const [getVoucherMonth, setVoucherMonth] = useState(0);
    const [getVoucherYear, setVoucherYear] = useState(new Date().getFullYear());
    const [getVoucherName, setVoucherName] = useState("");
    const [getVoucherUsageLimit, setVoucherUsageLimit] = useState(1);

    const [getIndex, setIndex] = useState(0);
    const [getNewQuestionnaireName, setNewQuestionnaireName] = useState("");
    const [getNewQuestionnaireDate, setNewQuestionnaireDate] = useState("");
    const [questionnaires, setQuestionnaires] = useState([{}]);
    const [coach, setCoach] = useState({});

    const [getParticipantFullName, setParticipantFullName] = useState("");
    const [getParticipantEmailAddress, setParticipantEmailAddress] = useState("");
    const [getParticipantCategory, setParticipantCategory] = useState("Team Member");
    const [getNewTeamName, setNewTeamName] = useState("");
    const [getNewDeadline, setNewDeadline] = useState("");
    const [getConfirmDelete, setConfirmDelete] = useState(false);
    const [getSearchTerm, setSearchTerm] = useState("");
    const [getVouchers, setVouchers] = useState([]);
    const [tableKey, setTableKey] = useState(0);
    const [newQuestionnaireKey, setNewQuestionnaireKey] = useState(0);
    const [oldQuestionnaireKey, setOldQuestionnaireKey] = useState(0);
    const [newPurchaseKey, setNewPurchaseKey] = useState(0);
    const [getTestEmail, setTestEmail] = useState("");
    const [getVoucherFilters, setVoucherFilters] = useState("");

    const doInvite = httpsCallable(fn, "inviteParticipants");
    const doRemind = httpsCallable(fn, "remindParticipants");

    const btnName = document.getElementById("btn_rename");
    const btnPurchase = document.getElementById("btn_purchase");
    const btnDeadline = document.getElementById("btn_deadline");
    const btnInvite = document.getElementById("btn_individual");
    const btnMulti = document.getElementById("btn_multiple");
    const btnRemind = document.getElementById("btn_remind");
    const btnTest = document.getElementById("btn_test");
    const btnDelete = document.getElementById("btn_delete");
    const btnCreate = document.getElementById("start_questionnaire");

    const VoucherStuff = [];

    const navigate = useNavigate();

    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const permittedCharacters = /^[ a-zA-Z0-9!()#$£"%&'+,-./:;<>?@_~]{0,256}$/;

    const handleKeyDown = (event) => {
        const input = event.key;
        if (permittedCharacters.test(input) && event.keyCode !== 13) return;
        event.preventDefault(); // Prevent the character from being entered
        toast.error("This character is not allowed. Please try again.");
    }

    useEffect(() => {
        const textAreas = document.querySelectorAll("textarea");
        const emailInputs = document.querySelectorAll('input[type="email"]');
        const textInputs = document.querySelectorAll('input[type="text"]');
        const passwordInputs = document.querySelectorAll('input[type="password"]');
        const inputTypes = [textAreas, emailInputs, textInputs, passwordInputs];

        for (let input of inputTypes) {
            if (input.length > 0) 
                for (let text of input)
                    text.addEventListener("keydown", (event) => handleKeyDown(event))
        }

    }, []);

    function publishAnonymous (qID) {
        const anons = JSON.parse(anonymous);

        const questionnaireLOC = doc(collection(doc(collection(db, `Coaches`), id), `Questionnaires`), qID)
        const participantsLOC = collection(questionnaireLOC, `Participants`);

        for (let anon of anons) {
            const data = {
                full_name: "",
                email_address: "",
                category: anon.category,
                q1: Number(anon.q1),
                q2: Number(anon.q2),
                q3: Number(anon.q3),
                q4: Number(anon.q4),
                q5: Number(anon.q5),
                q6: Number(anon.q6),
                q7: Number(anon.q7),
                q8: Number(anon.q8),
                q9: Number(anon.q9),
                q10: Number(anon.q10),
                q11: Number(anon.q11),
                q12: Number(anon.q12),
                q13: Number(anon.q13),
                q14: Number(anon.q14),
                q15: Number(anon.q15),
                q16: Number(anon.q16),
                q17: Number(anon.q17),
                q18: Number(anon.q18),
                q19: Number(anon.q19),
                q20: Number(anon.q20),
                q21: Number(anon.q21),
                q22: Number(anon.q22),
                q23: Number(anon.q23),
                q24: Number(anon.q24),
                q25: Number(anon.q25),
                q26: Number(anon.q26),
                q27: Number(anon.q27),
                q28: Number(anon.q28),
                q29: Number(anon.q29),
                q30: Number(anon.q30),
                q31: Number(anon.q31),
                q32: Number(anon.q32),
                q33: Number(anon.q33),
                q34: Number(anon.q34),
                q35: Number(anon.q35),
                q36: Number(anon.q36),
                q37: Number(anon.q37),
                q38: Number(anon.q38),
                q39: Number(anon.q39),
                q40: Number(anon.q40),
                q41: Number(anon.q41),
                q42: Number(anon.q42),
                qTS: anon.qTS,
                qAD: anon.qAD,
                qIA: anon.qIA
            };

            const settings = {
                merge: false
            };

            toast.promise(addDoc(participantsLOC, data, settings), {
                "error": "Could not add person"
            });
        }
    }

    async function purchaseReport () {

        const btn = btnPurchase;
        btn.style.opacity = "50%";
        btn.disabled = true;
        const timeout = setTimeout(() => {
            btn.style.opacity = "100%";
            btn.disabled = false;
        }, 10000);

        if (getVoucher !== "") {

            if (!/^[A-Z0-9]{0,32}$/.test(getVoucher)) {
                toast.error(`${getVoucher} is invalid or expired. Please check any spelling and try again.`)
                return;
            }

            const vLOC = doc(collection(db, "Vouchers"), getVoucher);
            const vDOC = await getDoc(vLOC);
            const voucher = vDOC.data();

            if (!voucher) {
                toast.error(`${getVoucher} is invalid or expired. Please check any spelling and your internet connection and try again.`)
                return;
            }

            const thisYear = new Date().getFullYear();
            const thisMonth = new Date().getMonth();

            const isFutureYear = voucher.expiration_year > thisYear;
            const isFutureMonth = voucher.expiration_year == thisYear && voucher.expiration_month >= thisMonth;
            const isUsable = voucher.usage_number < voucher.usage_limit;

            if (isUsable && (isFutureYear || isFutureMonth)) {
                toast.promise(updateDoc(vLOC, {usage_number: voucher.usage_number+1})
                .then(() => {
                    const qLOC = doc(collection(doc(collection(db, "Coaches"), id), "Questionnaires"), questionnaires[getIndex]["id"]);
                    toast.promise(updateDoc(qLOC, { purchased: true })
                    .then(() => {
                        questionnaires[getIndex]["purchased"] = true;
                        setNewPurchaseKey(newPurchaseKey + 1);
                        document.getElementById("SCROLL_TO").scrollIntoView({ top: 20, behavior: 'smooth' });
                        return;
                    }), {
                        success: `Successfully applied ${getVoucher}. This Team 360 Report was purchased for free.`,
                        error: `We were unable to apply ${getVoucher}. Please check your internet connection and try again.`
                    })
                }), {
                    loading: `Applying ${getVoucher} to purchase this Team 360 Report for free...`,
                    error: `${getVoucher} is invalid or expired. Please check any spelling and your internet connection and try again.`
                })
                return;
            } else {
                toast.error(`${getVoucher} is invalid or expired. Please check any spelling and your internet connection and try again.`)
                return;
            }

        }

        const buyNow = async () => {
            const stripe = await stripePromise;
            await stripe.redirectToCheckout({
                lineItems: [{
                    price: "price_1MfSIHHXZAeUxPGdKMBUyMjK", // "price_1Mgp6WHXZAeUxPGdVstKT0bZ"
                    quantity: 1
                }],
                mode: "payment",
                customerEmail: coach.email_address,
                successUrl: `https://creating-the-team-edge.web.app?q=${questionnaires[getIndex]["id"]}`,
                cancelUrl: `https://creating-the-team-edge.web.app/`,
            });
        }

        buyNow();

    }

    useEffect(() => {
        const fetchVouchers = async () => {
            const allVouchers = await getDocs(collection(db, "Vouchers"));
            setVouchers(allVouchers);
        }
        if (!isNullOrUndefined(coach.email_address) && (coach.email_address.includes("@performance-edge.co.uk") || coach.email_address.includes("lewis-harman@outlook.com") || coach.email_address.includes("paul@performance-edge.co.uk") || coach.email_address.includes("lucy@performance-edge.co.uk") || coach.email_address.includes("gemma@performance-edge.co.uk") )) {
            fetchVouchers();
        }
    }, [coach,]);

    const deleteVoucher = (voucherID) => {

        const removeVoucher = async () => {
            toast.promise(deleteDoc(doc(collection(db, "Vouchers"), voucherID))
            .then(() => {
                window.location.reload(true);
            }), {
                loading: `Deleting ${voucherID}...`,
                success: `Successfully deleted ${voucherID}. This voucher code cannot be used to purchase any future Team 360 Reports for free.`,
                error: `We were unable to delete this Team 360 Report. Please check your internet connection and try again.`
            })
        }
        if (!isNullOrUndefined(coach.email_address) && (coach.email_address.includes("@performance-edge.co.uk") || coach.email_address.includes("lewis-harman@outlook.com") || coach.email_address.includes("paul@performance-edge.co.uk") || coach.email_address.includes("lucy@performance-edge.co.uk") || coach.email_address.includes("gemma@performance-edge.co.uk") )) {
            removeVoucher();
        } else {
            toast.error(`You do not have permission to delete a voucher code.`)
        }
    }

    useEffect(() => {
        
        const gather = async () => {

            const cLOC = doc(collection(db, "Coaches"), id);
            const qLOC = collection(cLOC, "Questionnaires");

            const questionnaireID = params.get("q");
            if (!!questionnaireID) {
                const data = { "purchased": true };
                const q = doc(qLOC, questionnaireID)
                toast.promise(updateDoc(q, data)
                .finally(() => {
                    navigate("/");
                }), {
                    loading: `Processing payment...`,
                    success: `Successfully processed your payment. You have purchased this Team 360 Report for £200.`,
                    error: `We were unable to process your payment. Please check your internet connection and try again or contact us at info@performance-edge.co.uk for support.`
                })
            }

            const cDOC = await getDoc(cLOC);
            const qDOC = await getDocs(qLOC);
            
            const all_qs = qDOC.docs;
            const qs = [];

            all_qs.forEach((q) => {

                const qu = {
                    id: q.id,
                    team_name: q.data().team_name,
                    deadline: q.data().deadline,
                    purchased: q.data().purchased
                };

                qs.push(qu);

            });

            setCoach(cDOC.data());

            const sortedQuestionnaires = qs.sort((a, b) => {
                const dateA = new Date(a.deadline);
                const dateB = new Date(b.deadline);
                return dateB - dateA;
            });
            setQuestionnaires(sortedQuestionnaires);

        }

        gather();

    }, []);

    const open = () => {
        if (window.innerWidth >= "1000"){
            document.getElementById("sidebar").style.width = "40vw";
            document.getElementById("sidebar").style.paddingLeft = "15px";
        } else {
            document.getElementById("sidebar").style.width = "90vw";
            document.getElementById("sidebar").style.paddingLeft = "15px";
        }
        document.getElementById("sidebar").style.overflowY = "scroll";
        document.getElementById("add").style.display = "none";
    }

    const close = () => {
        document.getElementById("sidebar").style.width = "0vw";
        document.getElementById("sidebar").style.paddingLeft = "0px";
        document.getElementById("sidebar").style.overflowY = "hidden";
        document.getElementById("add").style.display = "block";
    }

    const changeTeam360 = (index) => {
        try {
            document.getElementsByClassName("views")[index].style.opacity = "50%";
            document.getElementsByClassName("views")[index].disabled = true;
            document.getElementsByClassName("views")[getIndex].style.opacity = "100%";
            document.getElementsByClassName("views")[getIndex].disabled = false;
            setIndex(index);
        } catch (e) {
            console.log(e);
        }
    }

    const renameTeam360 = (qID) => {

        if (!/^[ a-zA-Z0-9!()#$£"%&'+,-./:;<>?@_~]{1,50}$/.test(getNewTeamName)) {
            toast.error(`The new team name must be 1 to 50 characters, including letters, numbers, and some symbols. Please try again.`)
            return;
        }

        const questionnaireLOC = doc(collection(doc(collection(db, `Coaches`), id), `Questionnaires`), qID);
        const data = {
            "team_name": getNewTeamName
        };

        const btn = btnName;
        btn.style.opacity = "50%";
        btn.disabled = true;
        
        toast.promise(updateDoc(questionnaireLOC, data)
        .then(() => {
            questionnaires[getIndex]["team_name"] = getNewTeamName;
            setNewTeamName("");
        })
        .finally(() => {
            btn.style.opacity = "100%";
            btn.disabled = false;
        }), {
            loading: `Renaming the team...`,
            success: `Successfully renamed the team.`,
            error: `We were unable to rename the team. Please check your internet connection and try again.`
        })
    }

    const changeDeadline = (qID) => {

        if (!/^[0-9]{4}[-/]{1}[0-9]{1,2}[-/]{1}[0-9]{1,2}$/.test(getNewDeadline)) {
            toast.error("The new deadline must be in the format YYYY-MM-DD. Please try again.")
            return;
        }

        const deadline_date = getNewDeadline.split("-");
        const day = deadline_date[2];
        const month = months[deadline_date[1]-1];
        const year = deadline_date[0];

        const questionnaireLOC = doc(collection(doc(collection(db, `Coaches`), id), `Questionnaires`), qID);
        const data = {
            "deadline": `${day} ${month} ${year}`
        };

        const btn = btnDeadline;
        btn.style.opacity = "50%";
        btn.disabled = true;

        toast.promise(updateDoc(questionnaireLOC, data)
        .then(() => {
            questionnaires[getIndex]["deadline"] = `${day} ${month} ${year}`;
            setNewDeadline("");
        })
        .finally(() => {
            btn.style.opacity = "100%";
            btn.disabled = false;
        }), {
            loading: `Moving the deadline...`,
            success: `Successfully moved the deadline.`,
            error: `We were unable to move the deadline. Please check your internet connection and try again.`
        })

    }

    const deleteTeam360 = (qID) => {

        if (!getConfirmDelete) {
            toast.error("You must indicate you understand before deleting a Team 360. Please try again.")
            return;
        }

        const questionnaireLOC = doc(collection(doc(collection(db, `Coaches`), id), `Questionnaires`), qID);
        
        const btn = btnDelete;
        btn.style.opacity = "50%";
        btn.disabled = true;

        toast.promise(deleteDoc(questionnaireLOC)
        .then(() => {
            window.location.reload(true);
        })
        .finally(() => {
            btn.style.opacity = "100%";
            btn.disabled = false;
        }), {
            loading: `Deleting this Team 360...`,
            success: `Successfully deleted this team.`,
            error: `We were unable to delete this Team 360. Please check your internet connection and try again.`
        });
    }
    
    const createTeam360 = (team_name, deadline_date) => {

        if (!/^[ a-zA-Z0-9!()#$£"%&'+,-./:;<>?@_~]{1,50}$/.test(team_name) ) {
            toast.error("The team name must be 1 to 50 characters, including letters, numbers, and some symbols. Please try again.")
            return;
        }

        if (!/^[0-9]{4}[-/]{1}[0-9]{1,2}[-/]{1}[0-9]{1,2}$/.test(deadline_date)) {
            toast.error("The deadline date must be in the format YYYY-MM-DD. Please try again.")
            return;
        }

        deadline_date = deadline_date.split("-");
        const day = deadline_date[2];
        const month = months[deadline_date[1]-1];
        const year = deadline_date[0];
        deadline_date = `${day} ${month} ${year}`;

        if (!questionnaires.every((q) => !(q.team_name === team_name && q.deadline_date === deadline_date))) {
            toast.error("A Team 360 with this team name and deadline date already exists. Please change the team name or the deadline date and try again.")
            return;
        }

        const data = {
            team_name: team_name,
            deadline: deadline_date,
            purchased: false
        };

        const settings = {
            merge: false
        };

        const questionnairesLOC = collection(doc(collection(db, `Coaches`), id), `Questionnaires`);

        const btn = btnCreate;
        btn.style.opacity = "50%";
        btn.disabled = true;

        toast.promise(addDoc(questionnairesLOC, data, settings)
        .then((docRef) => {
            try {
            const questionnaire = {
                id: docRef.id,
                team_name: team_name,
                deadline: deadline_date,
                purchased: false
            };
            let indexOfNewQuestionnaire;
            if (questionnaires.length > 1) {
                const sortedQuestionnaires = [...questionnaires, questionnaire]
                .sort((a, b) => {
                    const dateA = new Date(a.deadline);
                    const dateB = new Date(b.deadline);
                    return dateB - dateA;
                });
                indexOfNewQuestionnaire = sortedQuestionnaires.findIndex((q) => q.id === questionnaire.id);
                setQuestionnaires(sortedQuestionnaires);
            } else if (questionnaires.length === 1) {
                setQuestionnaires([questionnaires[0], questionnaire]);
                indexOfNewQuestionnaire = 1;
            } else {
                setQuestionnaires([questionnaire]);
                indexOfNewQuestionnaire = 0;
            }
            const old = newQuestionnaireKey;
            setOldQuestionnaireKey(old);
            setNewQuestionnaireKey(docRef.id);
            close();

            setNewQuestionnaireDate("");
            setNewQuestionnaireName("");
            
            changeTeam360(indexOfNewQuestionnaire);
            document.getElementById("SCROLL_TO").scrollIntoView({ top: 20, behavior: 'smooth' });
            } catch (e) {
                console.error(e);
            }
        })
        .finally(() => {
            btn.style.opacity = "100%";
            btn.disabled = false;
        }), {
            loading: `Creating a new Team 360...`,
            success: `Successfully created a new Team 360.`,
            error: `We were unable to create a new Team 360. Please check your internet connection and try again.`
        })
    }

    const categories = ["Team Member", "Direct Report", "Team Commissioner", "Stakeholder"];

    const sendReminder = async () => {

        let num = 0;
        const teamName = questionnaires[getIndex]["team_name"];
        const deadlineDate = questionnaires[getIndex]["deadline"];
        const pDOC = await getDocs(collection(doc(collection(doc(collection(db, "Coaches"), id), "Questionnaires"), questionnaires[getIndex]["id"]), "Participants"));
        if (!pDOC.docs || pDOC.docs.length === 0) {
            toast.error(`We can only send reminders to a Team 360 with participants. Please add a participant and try again.`)
            return;
        }
        const btn = btnRemind;
        btn.style.opacity = "50%";
        btn.disabled = true;
        const timeout = setTimeout(() => {
            btn.style.opacity = "100%";
            btn.disabled = false;
        }, 20000);
        pDOC.forEach((index, count) => {
            ++num;
            const participant = index.data();
            if (participant.q42 == 0) {
                const link = `https://creating-the-team-edge.web.app/team360?c=${id}&q=${questionnaires[getIndex]["id"]}&p=${index.id}`;
                const data = {
                    participantName: `${participant.full_name}`,
                    participantEmail: `${participant.email_address}`,
                    participantCategory: `${participant.category}`,
                    teamName: teamName,
                    deadlineDate: deadlineDate,
                    questionnaireLink: link,
                    coachName: `${coach.first_name} ${coach.last_name}`,
                    coachEmail: coach.email_address
                };
                toast.promise(doRemind(data), {
                    error: `We were unable to send a reminder email to ${participant.full_name}. Please send their personal link manually.`
                })
            }
            console.log(`${num} ${pDOC.docs.length - 1}`)
            if (num >= pDOC.docs.length) {

                toast.success(`Successfully sent reminder emails.`);
            }
        })
    }

    const inviteParticipant = (qID) => {

        const questionnaireLOC = doc(collection(doc(collection(db, `Coaches`), id), `Questionnaires`), qID)
        const participantsLOC = collection(questionnaireLOC, `Participants`);

        const pCAT = getParticipantCategory;
        const pEMA = getParticipantEmailAddress;
        const pNAM = getParticipantFullName;

        if (!/^[ a-zA-Z0-9!()#$£"%&'+,-./:;<>?@_~]{1,128}$/.test(pNAM)) {
            toast.error("Their full name must be 1 to 128 characters, including letters, numbers, and some symbols. Please try again.")
            return;
        }

        if (!/^[ a-zA-Z0-9!()#$£"%&'+,-./:;<>?@_~]{1,128}$/.test(pEMA) || !/^[^@]*@[^@]*$/.test(pEMA))
        {
            toast.error("Their email address must be 1 to 128 characters, including letters, numbers, and some symbols. Please try again.")
            return;
        }

        if (!categories.includes(pCAT)) {
            toast.error("Their participant category must be: Team Member, Direct Report, Team Commissioner, or Stakeholder. Please try again.")
            return;
        }

        const data = {
            full_name: pNAM,
            email_address: pEMA,
            category: pCAT,
            q1: 0,
            q2: 0,
            q3: 0,
            q4: 0,
            q5: 0,
            q6: 0,
            q7: 0,
            q8: 0,
            q9: 0,
            q10: 0,
            q11: 0,
            q12: 0,
            q13: 0,
            q14: 0,
            q15: 0,
            q16: 0,
            q17: 0,
            q18: 0,
            q19: 0,
            q20: 0,
            q21: 0,
            q22: 0,
            q23: 0,
            q24: 0,
            q25: 0,
            q26: 0,
            q27: 0,
            q28: 0,
            q29: 0,
            q30: 0,
            q31: 0,
            q32: 0,
            q33: 0,
            q34: 0,
            q35: 0,
            q36: 0,
            q37: 0,
            q38: 0,
            q39: 0,
            q40: 0,
            q41: 0,
            q42: 0,
            qTS: "",
            qAD: "",
            qIA: ""
        };

        const settings = {
            merge: false
        };

        const btn = btnInvite;
        btn.style.opacity = "50%";
        btn.disabled = true;

        toast.promise(addDoc(participantsLOC, data, settings)
        .then((reference) => {
            const link = `https://creating-the-team-edge.web.app/team360?c=${id}&q=${qID}&p=${reference.id}`;
            const data = {
                participantName: `${pNAM}`,
                participantEmail: `${pEMA}`,
                participantCategory: `${pCAT}`,
                teamName: questionnaires[getIndex]["team_name"],
                deadlineDate: questionnaires[getIndex]["deadline"],
                questionnaireLink: link,
                coachName: `${coach.first_name} ${coach.last_name}`,
                coachEmail: coach.email_address
            };
            toast.promise(doInvite(data)
            .finally(() => {
                setTableKey(tableKey + 1);
                document.getElementById("SCROLL_TO").scrollIntoView({ top: 20, behavior: 'smooth' });
            }), {
                loading: `Sending invite email to the new participant...`,
                success: `Successfully sent an invite email to the new participant.`,
                error: `We were unable to send an invite email to the new participant. Please send their personal link manually.`
            })
        })
        .finally(() => {
            btn.style.opacity = "100%";
            btn.disabled = false;
        }), {
            loading: `Adding a new participant...`,
            success: `Successfully added a new participant.`,
            error: `We were unable to add a new participant. Please check your internet connection and try again.`
        })

    }

    const bulkInvite = async (file, qID) => {

        readXlsxFile(file)
        .then((rows) => {

            const info = rows.slice(21);

            const questionnaireLOC = doc(collection(doc(collection(db, `Coaches`), id), `Questionnaires`), qID)
            const participantsLOC = collection(questionnaireLOC, `Participants`);

            const btn = btnMulti;
            btn.style.opacity = "50%";
            btn.disabled = true;

            const timeout = setTimeout(() => {
                btn.style.opacity = "100%";
                btn.disabled = false;
            }, 20000);

            info.forEach((p, i) => {

                let pNAM = p[2];
                let pEMA = p[3];
                let pCAT = p[4];

                if (!!pNAM && !!pCAT && !!pEMA && categories.includes(pCAT) && /^[ a-zA-Z0-9!()#$£"%&'+,-./:;<>?@_~]{1,128}$/.test(pNAM) && /^[ a-zA-Z0-9!()#$£"%&'+,-./:;<>?@_~]{1,128}$/.test(pEMA) && /^[^@]*@[^@]*$/.test(pEMA)) {

                    const data = {
                        full_name: pNAM,
                        email_address: pEMA,
                        category: pCAT,
                        q1: 0,
                        q2: 0,
                        q3: 0,
                        q4: 0,
                        q5: 0,
                        q6: 0,
                        q7: 0,
                        q8: 0,
                        q9: 0,
                        q10: 0,
                        q11: 0,
                        q12: 0,
                        q13: 0,
                        q14: 0,
                        q15: 0,
                        q16: 0,
                        q17: 0,
                        q18: 0,
                        q19: 0,
                        q20: 0,
                        q21: 0,
                        q22: 0,
                        q23: 0,
                        q24: 0,
                        q25: 0,
                        q26: 0,
                        q27: 0,
                        q28: 0,
                        q29: 0,
                        q30: 0,
                        q31: 0,
                        q32: 0,
                        q33: 0,
                        q34: 0,
                        q35: 0,
                        q36: 0,
                        q37: 0,
                        q38: 0,
                        q39: 0,
                        q40: 0,
                        q41: 0,
                        q42: 0,
                        qTS: "",
                        qAD: "",
                        qIA: ""
                    };
    
                    const settings = {
                        merge: false
                    };
                
                    addDoc(participantsLOC, data, settings)
                    .then((reference) => {
                        const link = `https://creating-the-team-edge.web.app/team360?c=${id}&q=${qID}&p=${reference.id}`;
                        const data = {
                            participantName: `${pNAM}`,
                            participantEmail: `${pEMA}`,
                            participantCategory: `${pCAT}`,
                            teamName: questionnaires[getIndex]["team_name"],
                            deadlineDate: questionnaires[getIndex]["deadline"],
                            questionnaireLink: link,
                            coachName: `${coach.first_name} ${coach.last_name}`,
                            coachEmail: coach.email_address
                        };
                        toast.promise(doInvite(data), {
                            error: `We were unable to send an invite to ${pNAM} for the Team 360. Please send the personal link manually.`
                        })
                    })
                    .catch((e) => toast.error(`We were unable to add ${pNAM} to the Team 360. Please check your internet connection and add them manually.`))
                    .finally(() => {
                        if (!info[i+1][2]) {
                            toast.success(`Successfully invited participants to the Team 360. Please check every participant has been added correctly.`);
                            setTableKey(tableKey + 1);
                            document.getElementById("SCROLL_TO").scrollIntoView({ top: 20, behavior: 'smooth' });
                        }
                    });


                } else {

                    if (!!pNAM && !/^[ a-zA-Z0-9!()#$£"%&'+,-./:;<>?@_~]{0,128}$/.test(pNAM)) {
                        toast.error(`We were unable to add ${pNAM} to the Team 360. Please check their full name in the spreadsheet and add them manually.`)
                    }
            
                    if (!!pEMA && (!/^[ a-zA-Z0-9!()#$£"%&'+,-./:;<>?@_~]{0,128}$/.test(pEMA) || !/^[^@]*@[^@]*$/.test(pEMA)))
                    {
                        toast.error(`We were unable to add ${pNAM} to the Team 360. Please check their email address in the spreadsheet and add them manually.`)
                    }
            
                    if (!!pCAT && !categories.includes(pCAT)) {
                        toast.error(`We were unable to add ${pNAM} to the Team 360. Please check their participant category in the spreadsheet and add them manually.`)
                    }

                }

            })

        });

    }

    const testEmail = () => {

        if (!!getTestEmail && (!/^[ a-zA-Z0-9!()#$£"%&'+,-./:;<>?@_~]{0,128}$/.test(getTestEmail) || !/^[^@]*@[^@]*$/.test(getTestEmail)))
        {
            toast.error("The test email must be 1 to 128 characters, including letters, numbers, and some symbols. Please try again.")
            return;
        }

        const btn = btnTest;
        btn.style.opacity = "50%";
        btn.disabled = true;
        const timeout = setTimeout(() => {
            btn.style.opacity = "100%";
            btn.disabled = false;
        }, 20000);
        const link = `https://creating-the-team-edge.web.app/mini360/`;
        const data = {
            participantName: `- THIS IS A TEST`,
            participantEmail: `${getTestEmail}`,
            participantCategory: `TESTER`,
            teamName: `THIS IS A TEST`,
            deadlineDate: `NOT REAL`,
            questionnaireLink: link,
            coachName: `${coach.first_name} ${coach.last_name}`,
            coachEmail: coach.email_address
        };
        toast.promise(doInvite(data), {
            loading: `Sending a test email...`,
            success: `Successfully sent a test email. Please check your inbox and spam folders.`,
            error: `We were unable to send a test email. Please check your internet connection and try again.`
        })
        
    }

    const createVoucher = () => {
        if (!isNullOrUndefined(coach.email_address) && (coach.email_address.endsWith("@performance-edge.co.uk") || coach.email_address.includes("lewis-harman@outlook.com") )) {
            if (getVoucherUsageLimit > 0 && !(getVoucherUsageLimit % 1) && getVoucherMonth > -1 && getVoucherMonth < 12 && !(getVoucherMonth % 1) && getVoucherYear >= new Date().getFullYear() && !(getVoucherYear % 1) && /^[A-Z0-9]{1,32}$/.test(getVoucherName)) {
                const data = {
                    expiration_month: getVoucherMonth,
                    expiration_year: getVoucherYear,
                    usage_limit: getVoucherUsageLimit,
                    usage_number: 0
                };
                const settings = {
                    merge: false
                };
                const vLOC = doc(collection(db, `Vouchers`), getVoucherName);
                toast.promise(setDoc(vLOC, data, settings)
                .then(() => {
                    window.location.reload(true);
                    return;
                }), {
                    loading: `Creating a new voucher...`,
                    success: `Successfully created a new voucher.`,
                    error: `We were unable to create a new voucher. Please check your internet connection and try again.`
                })
                return;
            }
            toast.error("All voucher codes must be 1 to 128 characters, including capital letters and numbers, without spaces. The redemption limit should be at least 1 and the deadline date should be in the format DD-MM-YYYY and be set for the future.");
            return;
        }
        toast.error("You are not authorised to create a new voucher.")
    }

    const accreditation_codes = [];

    let current = new Date();
    for (let i = 0; i < 6; i++) {
        (i > 0) && current.setMonth(current.getMonth()+1);
        const year = current.getFullYear();
        const month = current.toLocaleString("en-GB", {month: "long"});
        const accreditationHash = String(CryptoJS.SHA512(`${month}${year}`)).substring(0, 8).toUpperCase();
        accreditation_codes.push(`CTE${accreditationHash}X`);
    }
    current = new Date();

    return (
        <React.Fragment>

            <div id="sidebar" class="sidebar">
                <a onClick={() => close()} class="close">&times;</a>
                <div class="side_section" id="manual_setup">
                    <h2>{text_portal["team360"]["new"]["questionnaire"]["heading"]}</h2>
                    <p>{text_portal["team360"]["new"]["questionnaire"]["caption"]}</p>
                    <label for="questionnaire_name">{text_portal["team360"]["new"]["questionnaire"]["form"]["name"]} ({50 - getNewQuestionnaireName.length} of 50 left)</label>
                    <input type="text" id="questionnaire_name" placeholder="Please type here..." value={getNewQuestionnaireName} onChange={(event) => setNewQuestionnaireName(event.target.value)} /><p></p>
                    <label for="questionnaire_date">{text_portal["team360"]["new"]["questionnaire"]["form"]["date"]}</label>
                    <input type="date" id="questionnaire_date" placeholder="Please type here..." value={getNewQuestionnaireDate} onChange={(event) => setNewQuestionnaireDate(event.target.value)} /><p></p>
                </div>
                <button class="primary" id="start_questionnaire" onClick={() => createTeam360(getNewQuestionnaireName, getNewQuestionnaireDate)}>{text_portal["team360"]["new"]["questionnaire"]["action"]}</button> 
                <Spacer /><Spacer />
            </div>

            <Top title={text_portal["page"]["meta"]["title"]} hero="">
                <Row>
                    <button class="primary space" id="settings" onClick={() => navigate("/settings")}>{text_portal["page"]["actions"]["settings"]}</button>
                    <button class="accent space" id="logout" onClick={
                        () => toast.promise(signOut(auth), {
                            loading: "Logging out...",
                            success: "Successfully logged out. Thank you for using our web application.",
                            error: "We were unable to logout of your account. Please check your internet connection and try again."
                        })
                    }>{text_portal["page"]["actions"]["logout"]}</button>
                </Row>
            </Top>

            <div class="scrollbar" id="scrollbar">
                <div class="container">
                <h2>{`Hello, ${coach.first_name} ${coach.last_name}`}</h2>
                <input type="text" id="search_term" placeholder="🔍 Search Team 360's..." value={getSearchTerm} onChange={(event) => setSearchTerm(event.target.value)} /> 
                <button class="primary" onClick={open}>Create New Team 360</button>
                <br /><br />
                {(!isNullOrUndefined(coach.email_address) && (coach.email_address.includes("@performance-edge.co.uk") || coach.email_address.includes("lewis-harman@outlook.com") )) &&
                        <React.Fragment>
                            <Row><Column size="100" side="left">
                                <details class="info big-gap">
                                    <summary>Create Voucher</summary>
                                    <hr />

                                    <label for="voucher_name">Voucher Code (numbers and capital letters only)</label><br />
                                    <input type="text" id="voucher_name" placeholder="Please type here..." value={getVoucherName} onChange={(event) => setVoucherName(event.target.value.replaceAll(" ", "").toUpperCase())} />

                                    <br />

                                    <label for="voucher_expiration_month">Expiration Month</label><br />
                                    <select id="voucher_expiration_month" onChange={(event) => setVoucherMonth(event.target.value)} >
                                        <option value="0">January</option>
                                        <option value="1">February</option>
                                        <option value="2">March</option>
                                        <option value="3">April</option>
                                        <option value="4">May</option>
                                        <option value="5">June</option>
                                        <option value="6">July</option>
                                        <option value="7">August</option>
                                        <option value="8">September</option>
                                        <option value="9">October</option>
                                        <option value="10">November</option>
                                        <option value="11">December</option>
                                    </select>

                                    <br />

                                    <label for="voucher_expiration_year">Expiration Year</label><br />
                                    <input type="number" id="voucher_expiration_year" placeholder="Please type here..." value={getVoucherYear} onChange={(event) => setVoucherYear(event.target.value)} />

                                    <br />

                                    <label for="voucher_usage_limit">Redemption Limit</label><br />
                                    <input type="number" id="voucher_usage_limit" placeholder="Please type here..." value={getVoucherUsageLimit} onChange={(event) => setVoucherUsageLimit(event.target.value)} />

                                    <br />

                                    <button class="accent" onClick={() => createVoucher()}>Create</button>

                                </details>
                            </Column></Row>
                        <Row><Column size="100" side="left">
                            <details class="info big-gap">
                                <summary>Available Vouchers</summary>
                                {
                                    (getVouchers.length > 0 && getVouchers.docs.length < 1) ?
                                    () => {
                                        const element =
                                        <React.Fragment>
                                            <p>No vouchers were found.</p>
                                            <br /><br />
                                        </React.Fragment>;
                                        VoucherStuff.push(element);
                                    } :
                                    getVouchers.forEach((voucher) => {
                                        if (!voucher.id.includes(getVoucherFilters)) return;
                                        VoucherStuff.push(<React.Fragment>
                                            <h3>{voucher.id}</h3>
                                            <p>Expires in {months[voucher.data().expiration_month]}, {voucher.data().expiration_year}.</p>
                                            <p>Used {voucher.data().usage_number} of {voucher.data().usage_limit} times.</p>
                                            <button class="accent" onClick={() => deleteVoucher(voucher.id)}>Delete Voucher</button>
                                            <br /><br />
                                        </React.Fragment>);
                                    })
                                }
                                {!(getVouchers.length > 0 && getVouchers.docs.length < 1) && <input type="text" id="search_vouchers" placeholder="🔍 Search Vouchers..." value={getVoucherFilters} onChange={(event) => setVoucherFilters(event.target.value.trim().toUpperCase())} /> }
                                {VoucherStuff}
                            </details>
                        </Column></Row>
                        <Row><Column size="100" side="left">
                            <details class="info big-gap">
                                <summary>Accreditation Codes</summary>
                                <hr />
                                { accreditation_codes.map((code, index) => {
                                    (index > 0) && current.setMonth(current.getMonth()+1);
                                    const year = current.getFullYear();
                                    const month = current.toLocaleString("default", {month: "long"});
                                    return <h3>For {month} {year}: {code}</h3>;  
                                })}
                            </details>
                            <Spacer /><hr /><Spacer />
                        </Column></Row>
                        </React.Fragment>
                }
                </div>
                <div class="scroller" id="scroller">
                    {(!newQuestionnaireKey && !questionnaires) ? 
                        <div class="scrollee">
                            <h3>{text_portal["team360"]["selector"]["empty"]["heading"]}</h3>
                            <p>{text_portal["team360"]["selector"]["empty"]["caption"]}</p>
                            <button class="views accent" onClick={() => open()}>{text_portal["team360"]["selector"]["empty"]["action"]}</button>
                        </div>
                        : questionnaires.map((team, index) =>
                            <div class={(typeof team.team_name === "string" && team.team_name.trim().toLowerCase().includes(getSearchTerm.trim().toLowerCase())) ? "scrollee" : "byebye"}>
                                <p>{text_portal["team360"]["selector"]["intro"]}</p>
                                <h3>{team.team_name}</h3>
                                <p>({team.deadline})</p>
                                <button class="views accent" disabled={index === getIndex} onClick={() => changeTeam360(index)}>{text_portal["team360"]["selector"]["action"]}</button>
                            </div>)
                    }
                </div>
            </div>

            <Spacer />

            <main>

                {!questionnaires[0] ?

                    <section id="introduction" class="container">
                        <Row><Column size="100" side="left">
                            <h2 id="SCROLL_TO">No Team 360s</h2>
                            <p>Please press the red '+' button in the top right corner to setup a new Team 360.</p>
                        </Column></Row>
                        <Spacer />
                    </section>

                :
                    <React.Fragment>
                    <section id="introduction" class="container">

                        <Row><Column size="100" side="left">
                            <h2 id="SCROLL_TO">{questionnaires[getIndex]["team_name"]}</h2>
                            <p class="description">({questionnaires[getIndex]["deadline"]})</p>
                        </Column></Row>
                    </section>
                    <section id="table">
                        
                        <Row><Column size="100" side="left">
                            <DisplayTable questionnaire={questionnaires[getIndex]} coachid={id} tableKey={tableKey} setTableKey={setTableKey} setStatistics={setStatistics} />
                        </Column></Row>

                        <br />
                    
                    </section>
                    <section id="options">

                        {(!isNullOrUndefined(coach.email_address) && (coach.email_address.includes("lewis-harman@outlook.com"))) && 
                            <Row><Column size="100" side="left">
                            <details class="info container">
                                <summary>Anonymous Participants</summary>
                                <hr />
                                <h3>1. Get the unique code (u) and questionnaire id (q) of the questionnaire on the legacy version.</h3>
                                <h3>2. Go to: https://creatingtheteamedge.com/?page_id=4799&u=UNIQUECODE&q=QUESTIONNAIREID.</h3>
                                <h3>3. Copy the object that has been console logged into the text area below.</h3>
                                <label for="anonymous">Copied Object:</label><br />
                                <textarea type="text" rows="5" id="anonymous" placeholder="Please type here..." value={anonymous} onChange={(event) => setAnonymous(event.target.value)}></textarea>
                                <button class="accent" onClick={() => publishAnonymous(questionnaires[getIndex]["id"])}>Add Participants</button>
                            </details>
                        </Column></Row>}

                        <Row><Column size="100" side="left">
                            <details class="info container">
                                <summary>Coach Resources</summary>
                                <hr />
                                <h3>A draft communication from Team Coaches to Team Leaders:</h3>
                                <p>A draft communication to inform Team Leaders about the information required to setup the Team 360 Questionnaire.</p>
                                <p>Please replace all placeholders (XXXXX) with suitable information before sending to the Team Leader.</p>
                                <a href={text_portal["team360"]["actions"]["upload"]["download_coach_draft"]} target="_blank"><button class="accent">Download Draft</button></a>
                                <hr />
                                <h3>A draft communication from Team Leaders to participants:</h3>
                                <p>A draft communication to inform participants they will receive an invite to complete a Team 360 Questionnaire.</p>
                                <p>Please replace the second and third placeholders (XXXXX) with the team name and deadline date respectively before providing to the Team Leader.</p>
                                <p>You should instruct the Team Leader to replace the first and last placeholders (XXXXX) with suitable information.</p>
                                <a href={text_portal["team360"]["actions"]["upload"]["download_leader_draft"]} target="_blank"><button class="accent">Download Draft</button></a>
                                <hr />
                                <h3>A draft communication from Team Coaches to IT Administrators:</h3>
                                <p>A draft communication to inform IT Administrators about the information required to pre-empt issues with sending emails.</p>
                                <p>Please replace all placeholders (XXXXX) with suitable information before sending to the IT Administrators.</p>
                                <a href={text_portal["team360"]["actions"]["upload"]["download_admin_draft"]} target="_blank"><button class="accent">Download Draft</button></a>
                            </details>
                        </Column></Row>

                        <Row><Column size="100" side="left">
                            <details class="info container">
                                <summary>{text_portal["team360"]["categories"]["title"]}</summary>
                                <hr />
                                <h3>{text_portal["team360"]["categories"]["Team Member"]}</h3>
                                <hr />
                                <h3>{text_portal["team360"]["categories"]["Direct Report"]}</h3>
                                <hr />
                                <h3>{text_portal["team360"]["categories"]["Team Commissioner"]}</h3>
                                <hr />
                                <h3>{text_portal["team360"]["categories"]["Stakeholder"]}</h3>
                            </details>
                        </Column></Row>

                        <Row><Column size="100" side="left">
                            <details class="info container">
                                <summary>Key Statistics</summary>
                                <hr />
                                <h3>Please refresh your browser window for more up-to-date statistics.</h3>
                                <hr />
                                {(getStatistics) && <>
                                    {(getStatistics.numParticipants === 0) ? <h3>No Participants have been invited to participate.</h3> : <h3>{getStatistics.totParticipants}% of questions for all {getStatistics.numParticipants} Participant(s) were completed.</h3>}
                                    {(getStatistics.numTeamMembers === 0) ? <h3>No Team Members have been invited to participate.</h3> : <h3>{getStatistics.totTeamMembers}% of questions for {getStatistics.numTeamMembers} Team Member(s) were completed.</h3>}
                                    {(getStatistics.numDirectReports === 0) ? <h3>No Direct Reports have been invited to participate.</h3> : <h3>{getStatistics.totDirectReports}% of questions for {getStatistics.numDirectReports} Direct Report(s) were completed.</h3>}
                                    {(getStatistics.numTeamCommissioners === 0) ? <h3>No Team Commissioners have been invited to participate.</h3> : <h3>{getStatistics.totTeamCommissioners}% of questions for {getStatistics.numTeamCommissioners} Team Commissioner(s) were completed.</h3>}
                                    {(getStatistics.numStakeholders === 0) ? <h3>No Stakeholders have been invited to participate.</h3> : <h3>{getStatistics.totStakeholders}% of questions for {getStatistics.numStakeholders} Stakeholder(s) were completed.</h3>}
                                </>}
                            </details>
                            <Spacer /><hr class="container" /><Spacer />
                        </Column></Row>

                        <Row><Column size="100" side="left">
                            <details class="container">
                                <summary>{text_portal["team360"]["actions"]["upload"]["title"]}</summary>
                                <hr />
                                <input type="file" id="spreadsheet_upload" accept=".xlsx" onChange={(event) => bulkInvite(event.target.files[0], questionnaires[getIndex]["id"])} />
                                <h3>{text_portal["team360"]["actions"]["upload"]["download_text"]}</h3>
                                <a href={text_portal["team360"]["actions"]["upload"]["download_link"]}><button class="primary">{text_portal["team360"]["actions"]["upload"]["download_action"]}</button></a>
                                <Spacer />
                                <h3>{text_portal["team360"]["actions"]["upload"]["upload_text"]}</h3>
                                <p>The button will disable itself for 20 seconds once pressed to avoid spam.</p>
                                <button id="btn_multiple" class="primary" onClick={() => document.getElementById("spreadsheet_upload").click()}>{text_portal["team360"]["actions"]["upload"]["upload_action"]}</button>
                                <a class="link accent" id="example_spreadsheet" href={text_portal["team360"]["actions"]["upload"]["example_link"]}>{text_portal["team360"]["actions"]["upload"]["example_text"]}</a>
                                <h3>{text_portal["team360"]["actions"]["upload"]["text"]}</h3>
                            </details>
                        </Column></Row>

                        <br />

                        <Row><Column size="100" side="left">
                            <details class="container">
                                <summary>{text_portal["team360"]["actions"]["append"]["title"]}</summary>
                                <hr />
                                <h3>{text_portal["team360"]["actions"]["append"]["text"]}</h3>
                                <br />
                                <label for="participant_full_name">{text_portal["team360"]["actions"]["append"]["name"]}</label><br/>
                                <input type="text" id="participant_full_name" placeholder="Please type here..." value={getParticipantFullName} onChange={(event) => setParticipantFullName(event.target.value)} />
                                <br />
                                <label for="participant_email_address">{text_portal["team360"]["actions"]["append"]["email"]}</label><br/>
                                <input type="email" id="participant_email_address" placeholder="Please type here..." value={getParticipantEmailAddress} onChange={(event) => setParticipantEmailAddress(event.target.value.replaceAll(" ", "").replaceAll(",", "").toLowerCase())} />
                                <br />
                                <label for="participant_category">{text_portal["team360"]["actions"]["append"]["category"]}</label><br/>
                                <select id="participant_category" onChange={(event) => setParticipantCategory(event.target.value)} >
                                    <option value="Team Member">Team Member</option>
                                    <option value="Direct Report">Direct Report</option>
                                    <option value="Team Commissioner">Team Commissioner</option>
                                    <option value="Stakeholder">Stakeholder</option>
                                </select> 
                                <br />
                                <button class="primary" id="btn_individual" onClick={() => inviteParticipant(questionnaires[getIndex]["id"])}>{text_portal["team360"]["actions"]["append"]["action"]}</button>
                            </details>
                        </Column></Row>
                        <br />
                        <Row><Column size="100" side="left">
                            <details class="container">
                                <summary>Remind Participants</summary>
                                <hr />
                                <p>We will instantly send a reminder email to any participant who has not fully completed their Team 360 Questionnaire automatically when you click the white 'Send' button. </p>
                                <p>The button will disable itself for 20 seconds once pressed to avoid spam.</p>
                                <button class="primary space" id="btn_remind" onClick={() => sendReminder()}>Send</button>
                            </details>
                        </Column></Row>
                        <br />
                        <Row><Column size="100" side="left">
                            <details class="container">
                                <summary>Test Email</summary>
                                <hr />
                                <p>We will instantly send a test email to the email address you have typed below.</p>
                                <p>The button will disable itself for 20 seconds once pressed to avoid spam.</p>
                                <label for="test_email">Email Address</label><br/>
                                <input type="email" id="test_email" placeholder="Please type here..." value={getTestEmail} onChange={(event) => setTestEmail(event.target.value.replaceAll(" ", "").replaceAll(",", "").toLowerCase())} />
                                <button class="primary space" id="btn_test" onClick={() => testEmail()}>Send</button>
                            </details>
                        </Column></Row>

                        {(newPurchaseKey >= 0 && !!questionnaires[getIndex]["purchased"]) &&
                            <Row><Column size="100" side="left">
                                <br />
                                <details class="container">
                                    <summary>Generate Report</summary>
                                    <hr />
                                    {(!isMobile) && <p>Thank you for purchasing this Team 360 Report. You can generate this Team 360 Report anytime and it will dynamically update with the latest responses from your Team 360 Questionnaire.</p>}
                                    {(!isMobile) && <p>This Team 360 Report will only generate if at least one participant has completed the Team 360 Questionnaire with 100% progress.</p>}
                                    {(!isMobile) && <p>The button will disable itself for 20 seconds once pressed to avoid spam.</p>}
                                    {(isMobile) && <p>Thank you for purchasing this Team 360 Report. This Team 360 Report cannot generate on phones and tablets - please use a desktop or laptop instead.</p>}
                                    <button class="primary space" disabled={isMobile} onClick={() => generateReport(id, coach, questionnaires[getIndex])}>Generate</button>
                                </details>
                            </Column></Row>
                        }

                        {(newPurchaseKey >= 0 && !questionnaires[getIndex]["purchased"]) &&
                            <Row><Column size="100" side="left"><br /><br/>
                                <details class="info container">
                                    <summary>{text_portal["team360"]["actions"]["purchase"]["title"]}</summary>
                                    <hr />
                                    <h3>{text_portal["team360"]["actions"]["purchase"]["warning"]}</h3>
                                    <p>{text_portal["team360"]["actions"]["purchase"]["text"]}</p>
                                    <label for="voucher_id">Voucher Code (optional)</label>
                                    <input type="text" id="voucher_id" placeholder="Please type here..." value={getVoucher} onChange={(event) => setVoucher(event.target.value.replaceAll(" ", "").toUpperCase())} />
                                    <br />
                                    <p>The button will disable itself for 10 seconds once pressed to avoid spam.</p>
                                    <button class="accent space" id="btn_purchase" onClick={() => purchaseReport(questionnaires[getIndex]["id"])}>Purchase</button>
                                </details>
                            </Column></Row>
                        }

                        <br />
                        <Row><Column size="100" side="left">
                            <details class="container">
                                <summary>{text_portal["team360"]["actions"]["rename"]["title"]}</summary>
                                <hr />
                                <h3>{text_portal["team360"]["actions"]["rename"]["text"]}</h3>
                                <label for="change_team_name">{text_portal["team360"]["actions"]["rename"]["name"]} ({50 - getNewTeamName.length} of 50 left)</label>
                                <input type="text" id="change_team_name" placeholder="Please type here..." value={getNewTeamName} onChange={(event) => setNewTeamName(event.target.value)} />
                                <Spacer />
                                <button id="btn_rename" class="primary" onClick={() => renameTeam360(questionnaires[getIndex]["id"])}>{text_portal["team360"]["actions"]["rename"]["action"]}</button>
                            </details>
                        </Column></Row>
                        <br />
                        <Row><Column size="100" side="left">
                            <details class="container">
                                <summary>{text_portal["team360"]["actions"]["deadline"]["title"]}</summary>
                                <hr />
                                <h3>{text_portal["team360"]["actions"]["deadline"]["text"]}</h3>
                                <label for="change_deadline">{text_portal["team360"]["actions"]["deadline"]["name"]}</label>
                                <input type="date" id="change_deadline" placeholder="Please type here..." value={getNewDeadline} onChange={(event) => setNewDeadline(event.target.value)} />
                                <Spacer />
                                <button id="btn_deadline" class="primary" onClick={() => changeDeadline(questionnaires[getIndex]["id"])}>{text_portal["team360"]["actions"]["deadline"]["action"]}</button>
                            </details>
                        </Column></Row>
                        <br />
                        <Row><Column size="100" side="left">
                            <details class="container">
                                <summary>{text_portal["team360"]["actions"]["delete"]["title"]}</summary>
                                <hr />
                                <h3>{text_portal["team360"]["actions"]["delete"]["text"]}</h3>
                                <input type="checkbox" id="confirm_delete" value={getConfirmDelete} onChange={(event) => setConfirmDelete(!getConfirmDelete)} />
                                <label for="confirm_delete">{text_portal["team360"]["actions"]["delete"]["confirm"]} <Spacer /></label>
                                
                                <button class="primary" id="btn_delete" onClick={() => deleteTeam360(questionnaires[getIndex]["id"])}>{text_portal["team360"]["actions"]["delete"]["action"]}</button>
                            </details>
                        </Column></Row>

                        <Row><Column size="100" side="left">
                        </Column></Row>

                        <Row><Column size="100" side="left">
                            <details class="info container">
                                <summary>Technical Support</summary>
                                <hr />
                                <h3>Step-by-step solutions to most problems can be solved quickly with the user guide and FAQs. If you are still unable to solve your issue, feel free to get in touch.</h3>
                                <a href="http://creating-the-team-edge.web.app/docs/start"><button class="accent">User Guide</button></a>
                                <a class="link primary" style={{color: 'var(--accent)'}} id="contact" href="mailto:info@performance-edge.co.uk">Contact Us</a>
                            </details>
                            <Spacer /><hr class="container" /><Spacer />
                        </Column></Row>
                    
                    </section>
                    <br />
                    </React.Fragment>
                }

                <div id="add" onClick={() => open()}>+</div>

            </main>

            <Bottom />


        </React.Fragment>
    );

}