import {h1, h2, h3, p, img, logo, page, sizes} from "../report";

export default
async function Cover (doc, teamName, deadline) {
    const offsetY = 40;
    h1   (doc, `Creating the Team Edge`,    {offsetY: offsetY+ 0, align: "center"});
    h2   (doc, `Team 360 Report`,           {offsetY: offsetY+30, align: "center"});
    p    (doc, `for the team named:`,       {offsetY: offsetY+45, align: "center"});
    h3   (doc, `${teamName}`,               {offsetY: offsetY+60, align: "center"});
    p    (doc, `(${deadline})`,             {offsetY: offsetY+80, align: "center"});
    logo (doc, sizes.fullPageWidth/2, offsetY+120);
}