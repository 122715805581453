// Imports libraries
import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

// TRIAL BUTTON <a href="/trial"><button class="primary space" id="join">{text_home["page"]["options"]["trial"]}</button></a>

// Imports loacles
import locale_home from "../locale/home.json";
import locale_characteristics from "../locale/characteristics.json";

// Imports components
import Row from "../components/atoms/row";
import Card from "../components/atoms/card";
import Column from "../components/atoms/column";
import Spacer from "../components/atoms/spacer";
import Banner from "../components/molecules/banner";
import Top from "../components/organisms/top";
import Aspect from "../components/organisms/aspect";
import Bottom from "../components/organisms/bottom";

// Imports models
import { authentication as auth } from "../../index";
import { signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import Cookies from "universal-cookie";
import { toast } from "react-hot-toast";

// Accesses home page
export default
function Home
(props)
{

    const navigate = useNavigate();

    const text_home = JSON.parse(JSON.stringify(locale_home));
    const text_characteristics = JSON.parse(JSON.stringify(locale_characteristics));

    const [getCoachEmailAddress, setCoachEmailAddress] = useState("");
    const [getCoachPassword, setCoachPassword] = useState("");

    const permittedCharacters = /^[ a-zA-Z0-9!()#$£"%&'+,-./:;<>?@_~]{0,256}$/;

    const handleKeyDown = (event) => {
        const input = event.key;
        if (permittedCharacters.test(input) && event.keyCode !== 13) return;
        event.preventDefault(); // Prevent the character from being entered
        toast.error("This character is not allowed. Please try again.");
    }

    useEffect(() => {
        const textAreas = document.querySelectorAll("textarea");
        const emailInputs = document.querySelectorAll('input[type="email"]');
        const textInputs = document.querySelectorAll('input[type="text"]');
        const passwordInputs = document.querySelectorAll('input[type="password"]');
        const inputTypes = [textAreas, emailInputs, textInputs, passwordInputs];

        for (let input of inputTypes) {
            if (input.length > 0) 
                for (let text of input)
                    text.addEventListener("keydown", (event) => handleKeyDown(event))
        }

    }, []);

    const callForgottenPassword = () => {
        const fp_email_address = prompt("Enter the email address for your account to reset your password.").replaceAll(" ", "").replaceAll(",", "").toLowerCase();
        toast.promise(sendPasswordResetEmail(auth, fp_email_address), {
            loading: "Sending password reset email...",
            success: "Successfully sent a password reset email. Please check your inbox and spam folders.",
            error: "Something went wrong. Please check your internet connection and try again."
        })
    }

    const open = () => {
        if (window.innerWidth >= "1000") {
            document.getElementById("sidebar").style.width = "40vw";
            document.getElementById("sidebar").style.paddingLeft = "15px";
        } else {
            document.getElementById("sidebar").style.width = "90vw";
            document.getElementById("sidebar").style.paddingLeft = "15px";
        }
        document.getElementById("sidebar").style.overflowY = "scroll";
    }

    const close = () => {
        document.getElementById("sidebar").style.width = "0vw";
        document.getElementById("sidebar").style.paddingLeft = "0px";
        document.getElementById("sidebar").style.overflowY = "hidden";
    }

    const coach = () => {

        if (!/^[a-zA-Z0-9!()#$£"%&'+,-./:;<>?@_~]{1,256}$/.test(getCoachEmailAddress) || !/^[^@]*@[^@]*$/.test(getCoachEmailAddress))
        {
            toast.error("Your email address or password was incorrect. Please try again.");
            return;
        }

        if (getCoachPassword.length < 6)
        {
            toast.error("Your email address or password was incorrect. Please try again.");
            return;
        }

        toast.promise(signInWithEmailAndPassword(auth, getCoachEmailAddress, getCoachPassword), {
            loading: "Logging into your account...",
            success: "Successfully logged into your account. You were sent to the Coach Portal.",
            error: "Your email address or password was incorrect. Please try again."
        });

    }

    return (
        <React.Fragment>

            <div id="sidebar" class="sidebar">
                <a onClick={() => close()} class="close">&times;</a>
                <div class="side_section" id="team_coach_1">
                    <h2>{text_home["sidebar"]["coaches"]["login"]["heading"]}</h2>
                    <p class="description">{text_home["sidebar"]["coaches"]["login"]["description"]}</p>
                    <label for="email">{text_home["sidebar"]["coaches"]["form"]["email_address"]}</label><br />
                    <input type="email" id="email" name="email" placeholder="Please type here..." value={getCoachEmailAddress} onChange={(event) => setCoachEmailAddress(event.target.value.replaceAll(" ", "").replaceAll(",", "").toLowerCase())} /><p></p>
                    <label for="password">{text_home["sidebar"]["coaches"]["form"]["password"]}</label><br />
                    <input type="password" id="password" name="password" placeholder="Please type here..." value={getCoachPassword} onChange={(event) => setCoachPassword(event.target.value)} /><p></p>
                </div>
                <button class="primary" onClick={() => coach()} id="coach_login">{text_home["sidebar"]["coaches"]["form"]["action"]}</button>   
                <a class="link accent" id="forgotten_password" onClick={() => callForgottenPassword()}>{text_home["sidebar"]["coaches"]["login"]["action"]}</a>
                <Spacer /><Spacer />
            </div>
            
            <Top title={text_home["page"]["meta"]["title"]} hero={text_home["page"]["meta"]["hero"]}>
                <Row>
                    <button onClick={() => open()} class="accent space" id="login">{text_home["page"]["options"]["login"]}</button>
                    <a href="/join"><button class="primary space" id="join">{text_home["page"]["options"]["join"]}</button></a>
                </Row>
            </Top>

            <main>

                <Spacer />

                <section id="introduction" class="container">

                    <Row>
                        <h2>{text_home["introduction"]["meta"]["heading"]}</h2>
                        <p class="description">{text_home["introduction"]["meta"]["description"]}</p>
                    </Row>

                    <Row>
                        <Aspect
                        icon={text_home["introduction"]["advantages"]["1"]["icon"]}
                        alt={`${text_home["introduction"]["advantages"]["1"]["quality"]} icon`}
                        heading={`1. ${text_home["introduction"]["advantages"]["1"]["quality"]}`}
                        description={text_home["introduction"]["advantages"]["1"]["description"]}
                        side="left"
                        /><Aspect
                        icon={text_home["introduction"]["advantages"]["2"]["icon"]}
                        alt={`${text_home["introduction"]["advantages"]["2"]["quality"]} icon`}
                        heading={`2. ${text_home["introduction"]["advantages"]["2"]["quality"]}`}
                        description={text_home["introduction"]["advantages"]["2"]["description"]}
                        side="right"
                        />
                    </Row>

                    <Row>
                        <Aspect
                        icon={text_home["introduction"]["advantages"]["3"]["icon"]}
                        alt={`${text_home["introduction"]["advantages"]["3"]["quality"]} icon`}
                        heading={`3. ${text_home["introduction"]["advantages"]["3"]["quality"]}`}
                        description={text_home["introduction"]["advantages"]["3"]["description"]}
                        side="left"
                        /><Aspect
                        icon={text_home["introduction"]["advantages"]["4"]["icon"]}
                        alt={`${text_home["introduction"]["advantages"]["4"]["quality"]} icon`}
                        heading={`4. ${text_home["introduction"]["advantages"]["4"]["quality"]}`}
                        description={text_home["introduction"]["advantages"]["4"]["description"]}
                        side="right"
                        />
                    </Row>

                    <Row>
                        <Aspect
                        icon={text_home["introduction"]["advantages"]["5"]["icon"]}
                        alt={`${text_home["introduction"]["advantages"]["5"]["quality"]} icon`}
                        heading={`5. ${text_home["introduction"]["advantages"]["5"]["quality"]}`}
                        description={text_home["introduction"]["advantages"]["5"]["description"]}
                        side="left"
                        /><Aspect
                        icon={text_home["introduction"]["advantages"]["6"]["icon"]}
                        alt={`${text_home["introduction"]["advantages"]["6"]["quality"]} icon`}
                        heading={`6. ${text_home["introduction"]["advantages"]["6"]["quality"]}`}
                        description={text_home["introduction"]["advantages"]["6"]["description"]}
                        side="right"
                        />
                    </Row>

                </section>

                <Spacer />
                <section class="container">
                <Row><Column size="100" side="left"><Banner theme="accent">
                    <Spacer />
                    <h2 class="no-space accent">Join our 'Creating the Team Edge' Community</h2>

                    <ul class="instructions">
                        <li><h3>Receive free resources.</h3></li>
                        <li><h3>Join our coaching programmes.</h3></li>
                        <li><h3>Find out about team journeys.</h3></li>
                        <li><h3>Attend our community of practice (CoP).</h3></li>
                    </ul>

                    <h3 class="accent">Get in touch to find out more.</h3>
                    <Spacer />
                    <a href="mailto:info@performance-edge.co.uk" target="_blank"><button class="primary">Contact Us</button></a>
                </Banner></Column></Row>
                </section>

                <Spacer />

                <section id="framework" class="container">

                    <Row>

                        <Column size="20" side="left">
                            <Card theme="primary"><img class="infographic left" draggable={false} src={text_home["framework"]["infographic"]} alt="The 'Creating the Team Edge' Framework Infographic" /></Card>
                        </Column>

                        <Column size="80" side="right">
                            <h2>{text_home["framework"]["meta"]["heading"]}</h2>
                            <p class="description">{text_home["framework"]["meta"]["description"]}</p>
                            <p>{text_home["framework"]["exploration"]["1"]}</p>
                            <p>{text_home["framework"]["exploration"]["2"]}</p>
                        </Column>

                    </Row>

                </section>

                <Spacer />

                <section id="programmes" class="container"><Banner theme="accent"><Column size="100" side="left">
                    <h2>{text_home["banners"]["1"]["heading"]}</h2>
                    <p class="description">{text_home["banners"]["1"]["description"]}</p>
                    <Spacer />
                    <a href={text_home["banners"]["1"]["link"]}><button class="primary">{text_home["banners"]["1"]["action"]}</button></a>
                </Column></Banner></section>

                <Spacer />

                <section id="characteristics" class="container">

                    <Row>
                        <h2>{text_home["characteristics"]["meta"]["heading"]}</h2>
                        <p class="description">{text_home["characteristics"]["meta"]["description"]}</p>
                    </Row>

                    <Row>
                        <Aspect
                        icon={text_characteristics["1"]["icon"]}
                        alt={`${text_characteristics["1"]["name"]} icon`}
                        heading={`1. ${text_characteristics["1"]["name"]}`}
                        description={text_characteristics["1"]["description"]}
                        side="left"
                        /><Aspect
                        icon={text_characteristics["2"]["icon"]}
                        alt={`${text_characteristics["2"]["name"]} icon`}
                        heading={`2. ${text_characteristics["2"]["name"]}`}
                        description={text_characteristics["2"]["description"]}
                        side="right"
                        />
                    </Row>

                    <Row>
                        <Aspect
                        icon={text_characteristics["3"]["icon"]}
                        alt={`${text_characteristics["3"]["name"]} icon`}
                        heading={`3. ${text_characteristics["3"]["name"]}`}
                        description={text_characteristics["3"]["description"]}
                        side="left"
                        /><Aspect
                        icon={text_characteristics["4"]["icon"]}
                        alt={`${text_characteristics["4"]["name"]} icon`}
                        heading={`4. ${text_characteristics["4"]["name"]}`}
                        description={text_characteristics["4"]["description"]}
                        side="right"
                        />
                    </Row>

                    <Row>
                        <Aspect
                        icon={text_characteristics["5"]["icon"]}
                        alt={`${text_characteristics["5"]["name"]} icon`}
                        heading={`5. ${text_characteristics["5"]["name"]}`}
                        description={text_characteristics["5"]["description"]}
                        side="left"
                        /><Aspect
                        icon={text_characteristics["6"]["icon"]}
                        alt={`${text_characteristics["6"]["name"]} icon`}
                        heading={`6. ${text_characteristics["6"]["name"]}`}
                        description={text_characteristics["6"]["description"]}
                        side="right"
                        />
                    </Row>

                    <Row>
                        <Aspect
                        icon={text_characteristics["7"]["icon"]}
                        alt={`${text_characteristics["7"]["name"]} icon`}
                        heading={`7. ${text_characteristics["7"]["name"]}`}
                        description={text_characteristics["7"]["description"]}
                        side="left"
                        />
                    </Row>

                </section>

                <Spacer />

                <section class="container" id="support"><Banner theme="accent"><Column size="100" side="left">
                    <h2>{text_home["banners"]["3"]["heading"]}</h2>
                    <p class="description">{text_home["banners"]["3"]["description"]}</p>
                    <Spacer />
                    <a href={text_home["banners"]["3"]["link-1"]}><button class="primary">{text_home["banners"]["3"]["action-1"]}</button></a>
                    <a class="link accent" id="contact" href={text_home.banners["3"].link-2}>{text_home["banners"]["3"]["action-2"]}</a>
                </Column></Banner></section>

                <Spacer />

                <section id="book" class="container">

                    <Row><Column size="100" side="left">
                        <h2>{text_home["book"]["meta"]["heading"]}</h2>
                        <p class="description">{text_home["book"]["meta"]["description"]}</p>
                    </Column></Row>

                    <Row>

                        <Column size="25" side="left">
                            <img class="infographic left" src={text_home["book"]["essentials"]["image"]} alt={text_home["book"]["essentials"]["title"]} draggable={false} />
                        </Column>

                        <Column size="75" side="right">

                            <Row>
                                <p>{text_home["book"]["information"]["1"]}</p>
                                <p>{text_home["book"]["information"]["2"]}</p>
                                <p>{text_home["book"]["information"]["3"]}</p>
                                <Row><center>

                                <Column size="25" side="left"><Spacer /><Card theme="auxiliary">
                                    <img draggable={false} class="icon left" src={text_home["book"]["purchase"][1]["icon"]} alt={text_home["book"]["purchase"][1]["location"]} />
                                    <h3>{text_home["book"]["purchase"][1]["location"]}</h3>
                                    <p>{text_home["book"]["purchase"][1]["description"]}</p>
                                    <a href={text_home["book"]["purchase"][1]["link"]}><button class="alternative">{text_home["book"]["purchase"][1]["action"]}</button></a>
                                </Card><Spacer /></Column>

                                <Column size="25" side="left"><Spacer /><Card theme="auxiliary">
                                    <img draggable={false} class="icon left" src={text_home["book"]["purchase"][2]["icon"]} alt={text_home["book"]["purchase"][2]["location"]} />
                                    <h3>{text_home["book"]["purchase"][2]["location"]}</h3>
                                    <p>{text_home["book"]["purchase"][2]["description"]}</p>
                                    <a href={text_home["book"]["purchase"][2]["link"]}><button class="alternative">{text_home["book"]["purchase"][2]["action"]}</button></a>
                                </Card><Spacer /></Column>

                                <Column size="25" side="left"><Spacer /><Card theme="auxiliary">
                                    <img draggable={false} class="icon left" src={text_home["book"]["purchase"][3]["icon"]} alt={text_home["book"]["purchase"][3]["location"]} />
                                    <h3>{text_home["book"]["purchase"][3]["location"]}</h3>
                                    <p>{text_home["book"]["purchase"][3]["description"]}</p>
                                    <a href={text_home["book"]["purchase"][3]["link"]}><button class="alternative">{text_home["book"]["purchase"][3]["action"]}</button></a>
                                </Card><Spacer /></Column>

                                <Column size="25" side="left"><Spacer /><Card theme="auxiliary">
                                    <img draggable={false} class="icon left" src={text_home["book"]["purchase"][4]["icon"]} alt={text_home["book"]["purchase"][4]["location"]} />
                                    <h3>{text_home["book"]["purchase"][4]["location"]}</h3>
                                    <p>{text_home["book"]["purchase"][4]["description"]}</p>
                                    <a href={text_home["book"]["purchase"][4]["link"]}><button class="alternative">{text_home["book"]["purchase"][4]["action"]}</button></a>
                                </Card><Spacer /></Column>

                            </center></Row>
                            </Row>

                        </Column>
                    
                    </Row>

                </section>

                <Spacer />

            </main>

            <Bottom />

        </React.Fragment>
    );

}