// Imports libraries
import React from "react";
import { useNavigate } from "react-router-dom";
import { useRef, useState, useEffect } from "react";

// Imports loacles
import locale_questionnaire from "../locale/questionnaire.json";
import locale_questions from "../locale/questions.json";
import locale_elements from "../locale/characteristics.json";

// Imports components
import Row from "../components/atoms/row";
import Card from "../components/atoms/card";
import Column from "../components/atoms/column";
import Spacer from "../components/atoms/spacer";
import Banner from "../components/molecules/banner";
import Top from "../components/organisms/top";
import Aspect from "../components/organisms/aspect";
import Bottom from "../components/organisms/bottom";

import { getDoc, doc, collection, updateDoc } from "firebase/firestore";
import { database as db } from "../../index";
import { toast } from "react-hot-toast";

export default
function Questionnaire
(props)
{

    const url = require('url');
    const link = new URL(document.URL);
    const params = link.searchParams;

    const coachid = params.get("c");
    const questionnaireid = params.get("q");
    const participantid = params.get("p");

    const text_questionnaire = JSON.parse(JSON.stringify(locale_questionnaire));
    const text_questions = JSON.parse(JSON.stringify(locale_questions));
    const text_elements = JSON.parse(JSON.stringify(locale_elements));
    const navigate = useNavigate();

    const [participant_name, setPN] = useState("");
    const [participant_email, setPE] = useState("");
    const [coach_name, setFN] = useState("");
    const [coach_company, setCN] = useState("");
    const [category, setCA] = useState("");
    const [team_name, setTN] = useState("");
    const [coach_email, setCE] = useState("");
    const [responses, setRE] = useState(new Array(42).fill(0));
    const [strengths, setST] = useState("");
    const [develop, setDE] = useState("");
    const [deadline_date, setDD] = useState("");
    const [ideas, setID] = useState("");
    const [responsesChanged, changeResponses] = useState(false);

    useEffect(() => {
        
        const gather = async () => {

            const cLOC = doc(collection(db, "Coaches"), coachid);
            const qLOC = doc(collection(cLOC, "Questionnaires"), questionnaireid);
            const pLOC = doc(collection(qLOC, "Participants"), participantid);

            const cDOC = await getDoc(cLOC);
            const qDOC = await getDoc(qLOC);
            const pDOC = await getDoc(pLOC);

            const coach = cDOC.data();
            const questionnaire = qDOC.data();
            const participant = pDOC.data();

            const res = [
                participant.q1,     participant.q2,     participant.q3,     participant.q4,     participant.q5,     participant.q6,
                participant.q7,     participant.q8,     participant.q9,     participant.q10,    participant.q11,    participant.q12,
                participant.q13,    participant.q14,    participant.q15,    participant.q16,    participant.q17,    participant.q18,
                participant.q19,    participant.q20,    participant.q21,    participant.q22,    participant.q23,    participant.q24,
                participant.q25,    participant.q26,    participant.q27,    participant.q28,    participant.q29,    participant.q30,
                participant.q31,    participant.q32,    participant.q33,    participant.q34,    participant.q35,    participant.q36,
                participant.q37,    participant.q38,    participant.q39,    participant.q40,    participant.q41,    participant.q42
            ];
            
            setFN(`${coach.first_name} ${coach.last_name}`);
            setCN(`${coach.company_name}`)
            setTN(`${questionnaire.team_name}`);
            setCE(`${coach.email_address}`);
            setPN(`${participant.full_name}`);
            setPE(`${participant.email_address}`);
            setCA(`${participant.category}`);
            setRE(res);
            setST(`${participant.qTS}`);
            setDE(`${participant.qAD}`);
            setID(`${participant.qIA}`);
            setDD(`${questionnaire.deadline}`);
        }

        gather();

        const elements = document.querySelectorAll(".no-paste"); 
        elements.forEach((element) => {
            element.addEventListener("paste", (event) => { 
                event.preventDefault(); 
                toast.error("Summary comments cannot be pasted. Please write any free-text summary comments manually.");
            }); 
        });

    }, []);

    async function changeResponse (numQuestion, newResponse) {
        if (newResponse < 1 || newResponse > 5 || !Number.isInteger(newResponse)) {
            toast.error(`That response is not permmited. Please try again.`)
            return;
        }
        if (numQuestion < 1 || numQuestion > 42 || !Number.isInteger(numQuestion)) {
            toast.error(`That question does not exist. Please try again.`);
            return;
        }
        const cLOC = doc(collection(db, "Coaches"), coachid);
        const qLOC = doc(collection(cLOC, "Questionnaires"), questionnaireid);
        const pLOC = doc(collection(qLOC, "Participants"), participantid);
        const res = [...responses];
        res[`${numQuestion-1}`] = newResponse;
        setRE(res);
        const data = {};
        data[`q${numQuestion}`] = newResponse;

        updateDoc(pLOC, data, {merge: true})
        .then(() => {
            toast.success(`Successfully saved your response to question ${numQuestion}`)
            changeResponses(true);
        })
        .catch(error => {
            toast.error(`We were unable to save your response to question ${numQuestion} with error message: ${error}`)
        })
        
    }
    const permittedCharacters = /^[ a-zA-Z0-9!()#$£"%&'+,-./:;<>?@_~]{0,256}$/;

    const handleKeyDown = (event) => {
        const input = event.key;
        if (permittedCharacters.test(input) && event.keyCode !== 13) return;
        event.preventDefault(); // Prevent the character from being entered
        toast.error("This character is not allowed. Please try again.");
    };

    function changeOpenResponses () {
        if (!!strengths && !permittedCharacters.test(strengths)) {
            toast.error(`The team strengths must be 0 to 256 characters, including letters, numbers, and some symbols. Please try again.`)
            return;
        }
        if (!!develop && !permittedCharacters.test(develop)) {
            toast.error(`The areas to develop must be 0 to 256 characters, including letters, numbers, and some symbols. Please try again.`)
            return;
        }
        if (!!ideas && !permittedCharacters.test(ideas)) {
            toast.error(`The ideas for action must be 0 to 256 characters, including letters, numbers, and some symbols. Please try again.`)
            return;
        }

        const data = {
            "qTS": strengths,
            "qAD": develop,
            "qIA": ideas
        };
        
        const cLOC = doc(collection(db, "Coaches"), coachid);
        const qLOC = doc(collection(cLOC, "Questionnaires"), questionnaireid);
        const pLOC = doc(collection(qLOC, "Participants"), participantid);
        toast.promise(updateDoc(pLOC, data, {merge: true})
        .then(() => {
            window.scrollTo(0, 0);  
            return Promise.resolve();
        })
        .then(() => {
            navigate("/thank-you");
        }), {
            loading: "Saving your summary comments...",
            success: "Successfully saved your summary comments. You have successfully completed this Team 360 Questionnaire. Thank you.",
            error: "We were unable to save your summary comments. Please check your internet connection and try again."
        })
    }   

    useEffect(() => {

        if (!responsesChanged) return;
        if (responses[41] > 0) return;
        const index = responses.indexOf(0);
        const latestQuestion = document.getElementById(`q${index+1}`);
        latestQuestion.scrollIntoView({ top: 20, behavior: 'smooth' });
        changeResponses(false);

    }, [responsesChanged]);

    return (
        <React.Fragment>
            
            <Top id="Hello" title={text_questionnaire["page"]["meta"]["title"]} hero={text_questionnaire["page"]["meta"]["hero"]} />
            
            <main class="container">

                <Spacer />

                <section><Banner theme="accent"><Column size="100" side="left">
                    <h2>{`${text_questionnaire["sections"][0]} ${participant_name} ${text_questionnaire["sections"][1]}`}</h2>
                    <h3>{text_questionnaire["sections"][2]} {team_name}{text_questionnaire["sections"][3]} <a class="accent" href="#categories" onClick={() => {
                        document.getElementById("categories").open = true;
                    }}>{category}</a> {text_questionnaire["sections"][5]}</h3>
                    <hr class="container" />
                    <h3>{text_questionnaire["sections"][8]} {coach_name} {text_questionnaire["sections"][9]} {coach_company}{text_questionnaire["sections"][10]} <a class="accent" href={`mailto:${coach_email}`}>{coach_email}</a> {text_questionnaire["sections"][11]}</h3>
                    <hr class="container" />
                    <Spacer />
                    <p>{text_questionnaire["sections"][20]}</p>
                    <a target="_blank" href={text_questionnaire["sections"][14]}><button class="primary">{text_questionnaire["sections"][13]}</button></a>
                </Column></Banner></section>
                
                <Spacer />

                <Row><Column size="100" side="left"> <hr/> <Spacer />
                    <details class="info" id="categories">
                        <summary>{text_questionnaire["categories"]["title"]}</summary>
                        <hr class="container" />
                        <ul class="categories">
                            <li><h3>{text_questionnaire["categories"]["Team Member"]}</h3></li>
                            <li><h3>{text_questionnaire["categories"]["Direct Report"]}</h3></li>
                            <li><h3>{text_questionnaire["categories"]["Team Commissioner"]}</h3></li>
                            <li><h3>{text_questionnaire["categories"]["Stakeholder"]}</h3></li>
                        </ul>
                    </details>
                    <Spacer /> <Spacer />
                </Column></Row>

                <Row><Column size="100" side="left">
                    <details class="info" id="categories">
                        <summary>{text_questionnaire["sections"][16]}</summary>
                        <hr class="container" />
                        <ul class="instructions">
                            <li><h3>{text_questionnaire["sections"][19]}</h3></li>
                            <li><h3>Your responses will provide a valuable insight into areas the team should celebrate and areas the team could develop.</h3></li>
                            <li><h3>{text_questionnaire["sections"][4]}</h3></li>
                            <li><h3>{text_questionnaire["sections"][17]}</h3></li>
                            <li><h3>Please make sure you have a strong, stable, and secure connection before starting.</h3></li>
                            <li><h3>Check each response has been saved before completing the next question.</h3></li>
                            <li><h3>Try refreshing the page and enabling cookies and JavaScript in your browser settings if you have saving issues.</h3></li>
                            <li><h3>{text_questionnaire["sections"][6]}</h3></li>
                            <li><h3>You cannot copy and paste your summary comments but you could draft them elsewhere.</h3></li>
                            <li><h3>If you lose connection, keep this window open in the background until you reconnect.</h3></li>
                            <li><h3>{text_questionnaire["sections"][7]}</h3></li>
                            <li><h3>{text_questionnaire["sections"][18]}</h3></li>
                            <li><h3>Team Commissioners may not be kept fully anonymous because this is often only one person.</h3></li>
                        </ul>
                    </details>
                </Column></Row>

                    {
                        responses.map((response, index) => {

                            const txtResponses = ["Not Answered", "Strongly Disagree", "Disagree", "Undecided", "Agree", "Strongly Agree"];

                            const numSection = Math.floor(index/6)+1;
                            const numQuestion = index + 1;

                            const output = (!index || !!response || !!responses[index-1] > 0)
                            &&
                                <React.Fragment>
                                    {   !(index % 6) &&
                                        <React.Fragment>
                                        <Spacer />
                                        <hr class="container" />
                                        <Spacer />
                                        <Row><Column size="100" side="left">
                                            <Banner theme="accent">
                                                <Row>
                                                    <Column size="100" side="left">
                                                        <div class="bubble"><img src={text_elements[numSection]["icon"]} alt={`${text_elements[numSection]["name"]} Icon`} /></div>
                                                    </Column>
                                                </Row>
                                            </Banner>
                                        </Column></Row>
                                        <Spacer />
                                        <Row><Column size="100" side="left">
                                            <Banner theme="primary">
                                                <Row>
                                                    <Column size="100" side="left">
                                                        <div class="section-text">
                                                            <h2 class="primary"><p class="primary">Section {numSection} of 7</p>{text_elements[numSection]["name"]}</h2>
                                                            <p class="primary section-description">{text_elements[numSection]["description"]}</p>
                                                        </div>
                                                    </Column>
                                                </Row>
                                            </Banner>
                                        </Column></Row>
                                        <Spacer />
                                        </React.Fragment>
                                    }
                                    <Spacer />
                                    <Row><Column size="100" side="left"><Banner theme="accent">
                                        <p id={`q${numQuestion}`}>Question {numQuestion} of 42</p>
                                        <h3>"{text_questions[numQuestion]}"</h3>
                                        <p class="accent">Latest Response: {txtResponses[response]}</p>
                                        <hr class="container" /><Spacer />
                                        <div class="option-buttons">
                                            <img draggable="false" onClick={() => changeResponse(index+1, 1)} src={text_questionnaire["buttons"]["stronglydisagree"]} alt="Strongly Disagree" />
                                            <img draggable="false" onClick={() => changeResponse(index+1, 2)} src={text_questionnaire["buttons"]["disagree"]} alt="Disagree" />
                                            <img draggable="false" onClick={() => changeResponse(index+1, 3)} src={text_questionnaire["buttons"]["undecided"]} alt="Undecided" />
                                            <img draggable="false" onClick={() => changeResponse(index+1, 4)} src={text_questionnaire["buttons"]["agree"]} alt="Agree" />
                                            <img draggable="false" onClick={() => changeResponse(index+1, 5)} src={text_questionnaire["buttons"]["stronglyagree"]} alt="Strongly Agree" />
                                        </div>
                                    </Banner></Column></Row>
                                </React.Fragment>

                            return output;

                        })
                    }

                    {

                        !!responses[41] && (
                            <React.Fragment>
                            <Spacer /><hr class="container" /><Spacer />
                            <Row><Column size="100" side="left"><Banner theme="primary">
                                <h2>Summary Comments</h2>
                                <hr class="container" /><Spacer />
                                <p class="primary">For security purposes, some characters are not allowed. Some common examples include:</p>
                                <ul>
                                    <li>Backslashes (\)</li>
                                    <li>Square brackets ([ and ])</li>
                                    <li>Backticks (`)</li>
                                    <li>New lines (Enter)</li>
                                </ul>
                                <p class="primary">To include multiple points, please number each point. For example: (1) XXXXX. (2) YYYYY. (3) ZZZZZ.</p>
                                <p class="primary">For formatting purposes, we do not allow you to copy and paste your summary comments.</p>
                                <Spacer /><hr class="container" /><Spacer />
                                <h3>Team Strengths</h3>
                                <label for="team_strengths">What should this team continue doing?</label>
                                <textarea class="no-paste" onKeyDown={(event) => handleKeyDown(event)} type="text" rows="5" maxLength="256" id="team_strengths" placeholder="Please type here..." value={strengths} onChange={(event) => setST(event.target.value)}></textarea>
                                <p class="primary">({strengths.length} of 256 characters used)</p>
                                <Spacer /><hr class="container" /><Spacer />
                                <h3>Areas to Develop</h3>
                                <label for="areas_to_develop">What should this team stop doing?</label>
                                <textarea class="no-paste" onKeyDown={(event) => handleKeyDown(event)} type="text" rows="5" maxLength="256" id="areas_to_develop" placeholder="Please type here..." value={develop} onChange={(event) => setDE(event.target.value)}></textarea>
                                <p class="primary">({develop.length} of 256 characters used)</p>
                                <Spacer /><hr class="container" /><Spacer />
                                <h3>Ideas for Action</h3>
                                <label for="ideas_for_action">What could this team start doing?</label>
                                <textarea class="no-paste" onKeyDown={(event) => handleKeyDown(event)} type="text" rows="5" maxLength="256" id="ideas_for_action" placeholder="Please type here..." value={ideas} onChange={(event) => setID(event.target.value)}></textarea>
                                <p class="primary">({ideas.length} of 256 characters used)</p>
                                <Spacer /><hr class="container" /><Spacer />
                                <button class="accent" onClick={() => changeOpenResponses()}>Finish</button>
                            </Banner></Column></Row>
                            </React.Fragment>
                        )

                    }

            </main>

            <Spacer />
            <Bottom />


        </React.Fragment>
    );
}