// Imports libraries
import React from "react";

// Creates custom columns
export default
function Row
(props)
{

    return (
        <div class="row">
            {props.children}
        </div>
    );

}