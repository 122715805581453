import {h2, h3, p, s, img, sizes, colours, k, t} from "../report";

function makePieChart(doc, stronglyagree, agree, undecided, disagree, stronglydisagree) {
    const data = [stronglyagree, agree, undecided, disagree, stronglydisagree];
    const canvas = document.createElement("canvas");
    canvas.width = 500;
    canvas.height = 500;
    const ctx = canvas.getContext("2d");
    const angles = data.map(d => d * 2 * Math.PI);
    const colors = [colours.stronglyagree, colours.agree, colours.undecided, colours.disagree, colours.stronglydisagree];
    let startAngle = 0;
    for (let i = 0; i < data.length; i++) {
        ctx.fillStyle = colors[i];
        ctx.beginPath();
        ctx.moveTo(canvas.width / 2, canvas.height / 2);
        ctx.arc(canvas.width / 2, canvas.height / 2, canvas.width / 3, startAngle, startAngle + angles[i]);
        ctx.lineTo(canvas.width / 2, canvas.height / 2);
        ctx.fill();
        startAngle += angles[i];
    }
    const imgData = canvas.toDataURL("image/png");
    doc.addImage(imgData, "PNG", -10, 70, 150, 150);

}

function midLevelBar(doc, title, data, offset) {

    let ratio;
    let size;
    let start = sizes.marginX;

    let stronglyagree = 0;
    let agree = 0;
    let undecided = 0;
    let disagree = 0;
    let stronglydisagree = 0;

    for (let datum of data) {
        if      (datum === 1) stronglydisagree++;
        else if (datum === 2) disagree++;
        else if (datum === 3) undecided++;
        else if (datum === 4) agree++;
        else if (datum === 5) stronglyagree++;
    }

    const total = stronglyagree + agree + undecided + disagree + stronglydisagree;

    t(doc, title, {offsetX: 0, offsetY: 30+offset});

    doc.setLineWidth(0);
    doc.setDrawColor(colours.secondary);

    ratio = stronglyagree / total;
    size = sizes.pageWidth * ratio;
    doc.setFillColor(colours.stronglyagree);
    doc.rect(start, sizes.marginY+32.5+offset, size, 6, "F");
    if (ratio >= 0.05) k(doc, `${Math.round(ratio*100)}%`, {offsetX: start-sizes.marginX+size/2, offsetY: 36.75+offset, sizeX: size, align: "center"});
    start = start + size;

    ratio = agree / total;
    size = sizes.pageWidth * ratio;
    doc.setFillColor(colours.agree);
    doc.rect(start, sizes.marginY+32.5+offset, size, 6, "F");
    if (ratio >= 0.05) k(doc, `${Math.round(ratio*100)}%`, {offsetX: start-sizes.marginX+size/2, offsetY: 36.75+offset, sizeX: size, align: "center"});
    start = start + size;

    ratio = undecided / total;
    size = sizes.pageWidth * ratio;
    doc.setFillColor(colours.undecided);
    doc.rect(start, sizes.marginY+32.5+offset, size, 6, "F");
    if (ratio >= 0.05) k(doc, `${Math.round(ratio*100)}%`, {offsetX: start-sizes.marginX+size/2, offsetY: 36.75+offset, sizeX: size, align: "center"});
    start = start + size;

    ratio = disagree / total;
    size = sizes.pageWidth * ratio;
    doc.setFillColor(colours.disagree);
    doc.rect(start, sizes.marginY+32.5+offset, size, 6, "F");
    if (ratio >= 0.05) k(doc, `${Math.round(ratio*100)}%`, {offsetX: start-sizes.marginX+size/2, offsetY: 36.75+offset, sizeX: size, align: "center"});
    start = start + size;

    ratio = stronglydisagree / total;
    size = sizes.pageWidth * ratio;
    doc.setFillColor(colours.stronglydisagree);
    doc.rect(start, sizes.marginY+32.5+offset, size, 6, "F");
    if (ratio >= 0.05) k(doc, `${Math.round(ratio*100)}%`, {offsetX: start-sizes.marginX+size/2, offsetY: 36.75+offset, sizeX: size, align: "center"});
    start = start + size;

    doc.setLineWidth(0.25);
    doc.rect(sizes.marginX, sizes.marginY+32.5+offset, sizes.pageWidth, 6, "S");

}

function lowLevelBar(doc, title, data, offset) {

    if (data.length > 0) {
        let ratio;
        let size;
        let start = sizes.marginX;

        let stronglyagree = 0;
        let agree = 0;
        let undecided = 0;
        let disagree = 0;
        let stronglydisagree = 0;

        for (let datum of data) {
            if      (datum === 1) stronglydisagree++;
            else if (datum === 2) disagree++;
            else if (datum === 3) undecided++;
            else if (datum === 4) agree++;
            else if (datum === 5) stronglyagree++;
        }

        const total = stronglyagree + agree + undecided + disagree + stronglydisagree;

        doc.setLineWidth(0);
        doc.setDrawColor(colours.secondary);

        ratio = stronglyagree / total;
        size = sizes.pageWidth / 5 * 4 * ratio;
        doc.setFillColor(colours.stronglyagree);
        doc.rect(start + sizes.pageWidth / 5, sizes.marginY+32.5+offset, size, 6, "F");
        if (ratio >= 0.05) k(doc, `${Math.round(ratio*100)}%`, {offsetX: start-sizes.marginX+size/2 + sizes.pageWidth / 5, offsetY: 36.75+offset, sizeX: size, align: "center"});
        start = start + size;

        ratio = agree / total;
        size = sizes.pageWidth / 5 * 4 * ratio;
        doc.setFillColor(colours.agree);
        doc.rect(start + sizes.pageWidth / 5, sizes.marginY+32.5+offset, size, 6, "F");
        if (ratio >= 0.05) k(doc, `${Math.round(ratio*100)}%`, {offsetX: start-sizes.marginX+size/2 + sizes.pageWidth / 5, offsetY: 36.75+offset, sizeX: size, align: "center"});
        start = start + size;

        ratio = undecided / total;
        size = sizes.pageWidth / 5 * 4 * ratio;
        doc.setFillColor(colours.undecided);
        doc.rect(start + sizes.pageWidth / 5, sizes.marginY+32.5+offset, size, 6, "F");
        if (ratio >= 0.05) k(doc, `${Math.round(ratio*100)}%`, {offsetX: start-sizes.marginX+size/2 + sizes.pageWidth / 5, offsetY: 36.75+offset, sizeX: size, align: "center"});
        start = start + size;

        ratio = disagree / total;
        size = sizes.pageWidth / 5 * 4 * ratio;
        doc.setFillColor(colours.disagree);
        doc.rect(start + sizes.pageWidth / 5, sizes.marginY+32.5+offset, size, 6, "F");
        if (ratio >= 0.05) k(doc, `${Math.round(ratio*100)}%`, {offsetX: start-sizes.marginX+size/2 + sizes.pageWidth / 5, offsetY: 36.75+offset, sizeX: size, align: "center"});
        start = start + size;

        ratio = stronglydisagree / total;
        size = sizes.pageWidth / 5 * 4 * ratio;
        doc.setFillColor(colours.stronglydisagree);
        doc.rect(start + sizes.pageWidth / 5, sizes.marginY+32.5+offset, size, 6, "F");
        if (ratio >= 0.05) k(doc, `${Math.round(ratio*100)}%`, {offsetX: start-sizes.marginX+size/2 + sizes.pageWidth / 5, offsetY: 36.75+offset, sizeX: size, align: "center"});
        start = start + size;
    }
    
    t(doc, title, {offsetX: 0, offsetY: 37+offset});
    doc.setLineWidth(0.25);
    doc.rect(sizes.marginX + sizes.pageWidth / 5, sizes.marginY+32.5+offset, sizes.pageWidth / 5 * 4, 6, "S");
    
    if (data.length === 0) {
        t(doc, `Not Available`, {offsetX: (sizes.pageWidth/5*4)/2 + sizes.pageWidth / 5, offsetY: 36.75+offset, sizeX: sizes.pageWidth / 5 * 4, align: "center"});
    }

} 








export default
async function Breakdown (doc, title, participants, min, max, description, txt1, txt2, txt3, txt4, txt5, txt6) {

    let elementOverview = [0, 0, 0, 0, 0]
    let totalResponses = 0;
    for (let i = min; i <= max; i++) {
        for (let num of participants.teamMembers[i-1]) {
            totalResponses++;
            elementOverview[num-1]++;
        }
        for (let num of participants.directReports[i-1]) {
            totalResponses++;
            elementOverview[num-1]++;
        }
        for (let num of participants.teamCommissioners[i-1]) {
            totalResponses++;
            elementOverview[num-1]++;
        }
        for (let num of participants.stakeholders[i-1]) {
            totalResponses++;
            elementOverview[num-1]++;
        }
    }

    // Element Overview
    
    doc.addPage("a4", "l");

    h2(doc, title, {offsetX: 20, offsetY: 7.5});
    img(doc, "/assets/images/icons/brands/performance-edge.png", 15, 10, 15, 15);

    doc.setDrawColor(colours.auxiliary);
    doc.setLineWidth(2);
    doc.line(sizes.marginX, sizes.marginY+15, doc.internal.pageSize.width-sizes.marginX, sizes.marginY+15);

    h3(doc, "Element Overview", {offsetX: 0, offsetY: 30});
    p(doc, description, {offsetX: 0, offsetY: 45, sizeX: sizes.pageWidth});

    makePieChart(
        doc,
        elementOverview[4] / totalResponses,
        elementOverview[3] / totalResponses,
        elementOverview[2] / totalResponses,
        elementOverview[1] / totalResponses,
        elementOverview[0] / totalResponses,
    );

    doc.setLineWidth(0.25);
    doc.setDrawColor(colours.secondary);

    doc.setFillColor(colours.stronglyagree);
    doc.rect(sizes.marginX+125, 100+17.5*0, 20, 20, "FD");
    p(doc, `Strongly Agree (${Math.round(elementOverview[4] / totalResponses * 100)}%)`, {offsetX: sizes.marginX+137.5, offsetY: 100+17.5*0-2.5, sizeX: sizes.pageWidth});

    doc.setFillColor(colours.agree);
    doc.rect(sizes.marginX+125, 100+17.5*1, 20, 20, "FD");
    p(doc, `Agree (${Math.round(elementOverview[3] / totalResponses * 100)}%)`, {offsetX: sizes.marginX+137.5, offsetY: 100+17.5*1-2.5, sizeX: sizes.pageWidth});

    doc.setFillColor(colours.undecided);
    doc.rect(sizes.marginX+125, 100+17.5*2, 20, 20, "FD");
    p(doc, `Undecided (${Math.round(elementOverview[2] / totalResponses * 100)}%)`, {offsetX: sizes.marginX+137.5, offsetY: 100+17.5*2-2.5, sizeX: sizes.pageWidth});

    doc.setFillColor(colours.disagree);
    doc.rect(sizes.marginX+125, 100+17.5*3, 20, 20, "FD");
    p(doc, `Disagree (${Math.round(elementOverview[1] / totalResponses * 100)}%)`, {offsetX: sizes.marginX+137.5, offsetY: 100+17.5*3-2.5, sizeX: sizes.pageWidth});

    doc.setFillColor(colours.stronglydisagree);
    doc.rect(sizes.marginX+125, 100+17.5*4, 20, 20, "FD");
    p(doc, `Strongly Disagree (${Math.round(elementOverview[0] / totalResponses * 100)}%)`, {offsetX: sizes.marginX+137.5, offsetY: 100+17.5*4-2.5, sizeX: sizes.pageWidth});

    // Summary of Responses
    
    doc.addPage("a4", "l");

    h2(doc, title, {offsetX: 20, offsetY: 7.5});
    img(doc, "/assets/images/icons/brands/performance-edge.png", 15, 10, 15, 15);

    doc.setDrawColor(colours.auxiliary);
    doc.setLineWidth(2);
    doc.line(sizes.marginX, sizes.marginY+15, doc.internal.pageSize.width-sizes.marginX, sizes.marginY+15);

    let txts = [txt1, txt2, txt3, txt4, txt5, txt6];

    h3(doc, "Summary of Responses", {offsetX: 0, offsetY: 30});

    let offset = 12.5;
    let count = 0;
    for (let i = min; i <= max; i++) {
        let data = [];
        data = data.concat(
            participants.teamMembers[i-1],
            participants.directReports[i-1],
            participants.teamCommissioners[i-1],
            participants.stakeholders[i-1],
        );
        midLevelBar(doc, `${i}. ${txts[count]}`, data, offset);
        offset = offset + 17.5;
        count++;
    }

    // Key

    offset = 140;
    p(doc, "Key", {offsetX: 0, offsetY: 30+offset});

    doc.setLineWidth(0);

    doc.setFillColor(colours.stronglyagree);
    doc.rect(sizes.marginX+sizes.pageWidth/5*0, sizes.marginY+32.5+offset, sizes.pageWidth/5, 6, "F");
    k(doc, "Strongly Agree", {offsetX: sizes.pageWidth/5*0+sizes.pageWidth/10, offsetY: 36.75+offset, sizeX: sizes.pageWidth/5, align: "center"});

    doc.setFillColor(colours.agree);
    doc.rect(sizes.marginX+sizes.pageWidth/5*1, sizes.marginY+32.5+offset, sizes.pageWidth/5, 6, "F");
    k(doc, "Agree", {offsetX: sizes.pageWidth/5*1+sizes.pageWidth/10, offsetY: 36.75+offset, sizeX: sizes.pageWidth/5, align: "center"});

    doc.setFillColor(colours.undecided);
    doc.rect(sizes.marginX+sizes.pageWidth/5*2, sizes.marginY+32.5+offset, sizes.pageWidth/5, 6, "F");
    k(doc, "Undecided", {offsetX: sizes.pageWidth/5*2+sizes.pageWidth/10, offsetY: 36.75+offset, sizeX: sizes.pageWidth/5, align: "center"});

    doc.setFillColor(colours.disagree);
    doc.rect(sizes.marginX+sizes.pageWidth/5*3, sizes.marginY+32.5+offset, sizes.pageWidth/5, 6, "F");
    k(doc, "Disagree", {offsetX: sizes.pageWidth/5*3+sizes.pageWidth/10, offsetY: 36.75+offset, sizeX: sizes.pageWidth/5, align: "center"});

    doc.setFillColor(colours.stronglydisagree);
    doc.rect(sizes.marginX+sizes.pageWidth/5*4, sizes.marginY+32.5+offset, sizes.pageWidth/5, 6, "F");
    k(doc, "Strongly Disagree", {offsetX: sizes.pageWidth/5*4+sizes.pageWidth/10, offsetY: 36.75+offset, sizeX: sizes.pageWidth/5, align: "center"})

    doc.setLineWidth(0.25);
    doc.rect(sizes.marginX, sizes.marginY+32.5+offset, sizes.pageWidth, 6, "S");
    
    // Breakdown of Responses
    
    doc.addPage("a4", "l");

    h2(doc, title, {offsetX: 20, offsetY: 7.5});
    img(doc, "/assets/images/icons/brands/performance-edge.png", 15, 10, 15, 15);

    doc.setDrawColor(colours.auxiliary);
    doc.setLineWidth(2);
    doc.line(sizes.marginX, sizes.marginY+15, doc.internal.pageSize.width-sizes.marginX, sizes.marginY+15);

    h3(doc, "Breakdown of Responses", {offsetX: 0, offsetY: 30});

    t(doc, `${min+0}. ${txt1}`, {offsetX: 0, offsetY: sizes.marginY+34+(36*0)});
    lowLevelBar(doc, "Team Members", participants.teamMembers[min-1+0], 18+0+(36*0));
    lowLevelBar(doc, "Direct Reports", participants.directReports[min-1+0], 18+6+(36*0));
    lowLevelBar(doc, "Team Commissioners", participants.teamCommissioners[min-1+0], 18+12+(36*0));
    lowLevelBar(doc, "Stakeholders", participants.stakeholders[min-1+0], 18+18+(36*0));

    t(doc, `${min+1}. ${txt2}`, {offsetX: 0, offsetY: sizes.marginY+34+(36*1)});
    lowLevelBar(doc, "Team Members", participants.teamMembers[min-1+1], 18+0+(36*1));
    lowLevelBar(doc, "Direct Reports", participants.directReports[min-1+1], 18+6+(36*1));
    lowLevelBar(doc, "Team Commissioners", participants.teamCommissioners[min-1+1], 18+12+(36*1));
    lowLevelBar(doc, "Stakeholders", participants.stakeholders[min-1+1], 18+18+(36*1));

    t(doc, `${min+2}. ${txt3}`, {offsetX: 0, offsetY: sizes.marginY+34+(36*2)});
    lowLevelBar(doc, "Team Members", participants.teamMembers[min-1+2], 18+0+(36*2));
    lowLevelBar(doc, "Direct Reports", participants.directReports[min-1+2], 18+6+(36*2));
    lowLevelBar(doc, "Team Commissioners", participants.teamCommissioners[min-1+2], 18+12+(36*2));
    lowLevelBar(doc, "Stakeholders", participants.stakeholders[min-1+2], 18+18+(36*2));

    // Key

    offset = 140;
    p(doc, "Key", {offsetX: 0, offsetY: 30+offset});

    doc.setLineWidth(0);

    doc.setFillColor(colours.stronglyagree);
    doc.rect(sizes.marginX+sizes.pageWidth/5*0, sizes.marginY+32.5+offset, sizes.pageWidth/5, 6, "F");
    k(doc, "Strongly Agree", {offsetX: sizes.pageWidth/5*0+sizes.pageWidth/10, offsetY: 36.75+offset, sizeX: sizes.pageWidth/5, align: "center"});

    doc.setFillColor(colours.agree);
    doc.rect(sizes.marginX+sizes.pageWidth/5*1, sizes.marginY+32.5+offset, sizes.pageWidth/5, 6, "F");
    k(doc, "Agree", {offsetX: sizes.pageWidth/5*1+sizes.pageWidth/10, offsetY: 36.75+offset, sizeX: sizes.pageWidth/5, align: "center"});

    doc.setFillColor(colours.undecided);
    doc.rect(sizes.marginX+sizes.pageWidth/5*2, sizes.marginY+32.5+offset, sizes.pageWidth/5, 6, "F");
    k(doc, "Undecided", {offsetX: sizes.pageWidth/5*2+sizes.pageWidth/10, offsetY: 36.75+offset, sizeX: sizes.pageWidth/5, align: "center"});

    doc.setFillColor(colours.disagree);
    doc.rect(sizes.marginX+sizes.pageWidth/5*3, sizes.marginY+32.5+offset, sizes.pageWidth/5, 6, "F");
    k(doc, "Disagree", {offsetX: sizes.pageWidth/5*3+sizes.pageWidth/10, offsetY: 36.75+offset, sizeX: sizes.pageWidth/5, align: "center"});

    doc.setFillColor(colours.stronglydisagree);
    doc.rect(sizes.marginX+sizes.pageWidth/5*4, sizes.marginY+32.5+offset, sizes.pageWidth/5, 6, "F");
    k(doc, "Strongly Disagree", {offsetX: sizes.pageWidth/5*4+sizes.pageWidth/10, offsetY: 36.75+offset, sizeX: sizes.pageWidth/5, align: "center"})

    doc.setLineWidth(0.25);
    doc.rect(sizes.marginX, sizes.marginY+32.5+offset, sizes.pageWidth, 6, "S");

    // Breakdown of Responses, continued
    
    doc.addPage("a4", "l");

    h2(doc, title, {offsetX: 20, offsetY: 7.5});
    img(doc, "/assets/images/icons/brands/performance-edge.png", 15, 10, 15, 15);

    doc.setDrawColor(colours.auxiliary);
    doc.setLineWidth(2);
    doc.line(sizes.marginX, sizes.marginY+15, doc.internal.pageSize.width-sizes.marginX, sizes.marginY+15);

    h3(doc, "Breakdown of Responses, continued", {offsetX: 0, offsetY: 30});

    t(doc, `${min+3}. ${txt4}`, {offsetX: 0, offsetY: sizes.marginY+34+(36*0)});
    lowLevelBar(doc, "Team Members", participants.teamMembers[min-1+3], 18+0+(36*0));
    lowLevelBar(doc, "Direct Reports", participants.directReports[min-1+3], 18+6+(36*0));
    lowLevelBar(doc, "Team Commissioners", participants.teamCommissioners[min-1+3], 18+12+(36*0));
    lowLevelBar(doc, "Stakeholders", participants.stakeholders[min-1+3], 18+18+(36*0));

    t(doc, `${min+4}. ${txt5}`, {offsetX: 0, offsetY: sizes.marginY+34+(36*1)});
    lowLevelBar(doc, "Team Members", participants.teamMembers[min-1+4], 18+0+(36*1));
    lowLevelBar(doc, "Direct Reports", participants.directReports[min-1+4], 18+6+(36*1));
    lowLevelBar(doc, "Team Commissioners", participants.teamCommissioners[min-1+4], 18+12+(36*1));
    lowLevelBar(doc, "Stakeholders", participants.stakeholders[min-1+4], 18+18+(36*1));

    t(doc, `${min+5}. ${txt6}`, {offsetX: 0, offsetY: sizes.marginY+34+(36*2)});
    lowLevelBar(doc, "Team Members", participants.teamMembers[min-1+5], 18+0+(36*2));
    lowLevelBar(doc, "Direct Reports", participants.directReports[min-1+5], 18+6+(36*2));
    lowLevelBar(doc, "Team Commissioners", participants.teamCommissioners[min-1+5], 18+12+(36*2));
    lowLevelBar(doc, "Stakeholders", participants.stakeholders[min-1+5], 18+18+(36*2));

    // Key

    offset = 140;
    p(doc, "Key", {offsetX: 0, offsetY: 30+offset});

    doc.setLineWidth(0);

    doc.setFillColor(colours.stronglyagree);
    doc.rect(sizes.marginX+sizes.pageWidth/5*0, sizes.marginY+32.5+offset, sizes.pageWidth/5, 6, "F");
    k(doc, "Strongly Agree", {offsetX: sizes.pageWidth/5*0+sizes.pageWidth/10, offsetY: 36.75+offset, sizeX: sizes.pageWidth/5, align: "center"});

    doc.setFillColor(colours.agree);
    doc.rect(sizes.marginX+sizes.pageWidth/5*1, sizes.marginY+32.5+offset, sizes.pageWidth/5, 6, "F");
    k(doc, "Agree", {offsetX: sizes.pageWidth/5*1+sizes.pageWidth/10, offsetY: 36.75+offset, sizeX: sizes.pageWidth/5, align: "center"});

    doc.setFillColor(colours.undecided);
    doc.rect(sizes.marginX+sizes.pageWidth/5*2, sizes.marginY+32.5+offset, sizes.pageWidth/5, 6, "F");
    k(doc, "Undecided", {offsetX: sizes.pageWidth/5*2+sizes.pageWidth/10, offsetY: 36.75+offset, sizeX: sizes.pageWidth/5, align: "center"});

    doc.setFillColor(colours.disagree);
    doc.rect(sizes.marginX+sizes.pageWidth/5*3, sizes.marginY+32.5+offset, sizes.pageWidth/5, 6, "F");
    k(doc, "Disagree", {offsetX: sizes.pageWidth/5*3+sizes.pageWidth/10, offsetY: 36.75+offset, sizeX: sizes.pageWidth/5, align: "center"});

    doc.setFillColor(colours.stronglydisagree);
    doc.rect(sizes.marginX+sizes.pageWidth/5*4, sizes.marginY+32.5+offset, sizes.pageWidth/5, 6, "F");
    k(doc, "Strongly Disagree", {offsetX: sizes.pageWidth/5*4+sizes.pageWidth/10, offsetY: 36.75+offset, sizeX: sizes.pageWidth/5, align: "center"})

    doc.setLineWidth(0.25);
    doc.rect(sizes.marginX, sizes.marginY+32.5+offset, sizes.pageWidth, 6, "S");

}