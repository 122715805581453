import {h2, h3, p, s, w, img, sizes, colours, k, t} from "../report";

const questions = [

    "1. This team has a clear and compelling reason for existence - its work is meaningful, worthwhile, and challenging.",
    "2. Each team member understands how the work of the team contributes to the vision, strategy, and goals of both the organisation and its external stakeholders.",
    "3. The team has collective goals that they can only achieve by working together.",
    "4. Team members understand how they personally contribute towards the team's success.",
    "5. When the team fails to achieve collective goals, each team member personally takes responsibility to improve the team's performance.",
    "6. Performance is regularly reviewed by this team using clear and relevant metric.",

    "7. This team has a unique character and personality that any team member would be proud to identify with.",
    "8. People from outside this team say they can count on the team to do what they say they will do.",
    "9. This team has a reputation for delivering excellent work among its stakeholders and partners.",
    "10. This team is known for the energy and passion that team members bring to work every day.",
    "11. People want to work in this team because it is renowned for having a positive work culture which delivers business success.",
    "12. This team is known for being inclusive as team members are encouraged to take part, and feel listened to and valued for who they are.",

    "13. Team members are agreed on how to behave as a team, when together and apart.",
    "14. Team members treat each other with dignity and respect.",
    "15. This team shows a commitment to team diversity, sustainability, and ethical practice.",
    "16. Team members value collective success more than individual achievement.",
    "17. Team members believe that it is important for this team to be a team.",
    "18. This team's shared values and beliefs shine through in all of its work projects.",

    "19. Members of this team appreciate each other's strengths and how these contribute to team performance.",
    "20. Team members are self-aware, helping them to interact effectively with others.",
    "21. The team proactively interacts with both internal and external stakeholders.",
    "22. The team know how they are interconnected with others, both within and outside of their organisation.",
    "23. The team are committed to surfacing performance issues and addressing these quickly and fairly.",
    "24. Members of this team have personal development plans in place and share progress openly with each other.",

    "25. Relationships in this team are built on trust - team members are open and honest with each other.",
    "26. Team members take time to get to know each other - not just what they do, but who they are.",
    "27. Team members are never shy of having difficult conversations about the quality of work in this team.",
    "28. The safe and open environment within this team encourages team members to express their ideas and risks without fear.",
    "29. Conflict in this team is used in a productive way to help achieve win-win solutions.",
    "30. This team has a high level of both support and challenge, enabling the team to give and receive constructive feedback.",

    "31. The team is clear on how it makes collective decisions, with team members committing wholeheartedly once a decision is reached.",
    "32. This team is known for the effectiveness of its meetings - they are thought-provoking, engaging, and outcome-focussed.",
    "33. Information is exchanged between team members and other teams in a clear and accurate manner.",
    "34. This team is continually in touch with its customers and stakeholders, and is committed to helping them succeed.",
    "35. This team is known for its agility, helping it to adapt and deliver in the face of challenge and change.",
    "36. The team continually review internal and external processes to help improve its effectiveness.",

    "37. This team gets together frequently to identiy new ways to grow and sustain its development.",
    "38. Everyone in the team is committed to exploring how good the team can be.",
    "39. This team embraces change and is known for its new and innovative working practices.",
    "40. Team members proactively share their learning, new ideas, and best practice with each other.",
    "41. This is a diverse team with an open and inclusive culture that allows every team member to have a voice.",
    "42. The team pays attention to the link between the team's well-being and performance."

];

function getLargestIndexes(arr) {
    let elements = arr.map((value, index) => ({ value, index }));
    elements.sort((a, b) => b.value - a.value);
    let largestIndexes = elements.slice(0, 7).map(element => element.index);
    return largestIndexes;
}

export default
async function Summary (doc, participants) {
    
    // Calculations

    let favourables = [];
    let neutrals = [];
    let unfavourables = [];

    for (let i = 1; i <= 42; i++) {
        let favourable = 0;
        let neutral = 0;
        let unfavourable = 0;
        let data = [];
        data = data.concat(
            participants.teamMembers[i-1],
            participants.directReports[i-1],
            participants.teamCommissioners[i-1],
            participants.stakeholders[i-1],
        );
        for (let datum of data) {
            if (datum === 4 || datum === 5) favourable++;
            else if (datum === 3) neutral++;
            else if (datum === 1 || datum === 2) unfavourable++;
        }
        favourables.push(favourable);
        neutrals.push(neutral);
        unfavourables.push(unfavourable);
    }

    let fav = getLargestIndexes(favourables);
    let neu = getLargestIndexes(neutrals);
    let unf = getLargestIndexes(unfavourables);

    // Top 7

    doc.addPage("a4", "l");

    h2(doc, "Top, Middle, and Bottom", {offsetX: 20, offsetY: 7.5});
    img(doc, "/assets/images/icons/brands/performance-edge.png", 15, 10, 15, 15);

    doc.setDrawColor(colours.auxiliary);
    doc.setLineWidth(2);
    doc.line(sizes.marginX, sizes.marginY+15, doc.internal.pageSize.width-sizes.marginX, sizes.marginY+15);

    h3(doc, "Top 7: Areas to Celebrate", {offsetY: 30});

    doc.setFillColor(colours.favourable);
    doc.rect(sizes.marginX, 47.5, sizes.pageWidth, 30, "F");
    w(doc, "Below are 7 areas to celebrate, showing the areas ranked most 'favourable' by participants.", {offsetX: 1, offsetY: 40, sizeX: sizes.pageWidth-2, align: "left"});
    w(doc, "They are the questions ranked with the highest number of 'strongly agree' and 'agree' ratings combined.", {offsetX: 1, offsetY: 47.5, sizeX: sizes.pageWidth-2, align: "left"});
    w(doc, "All areas are displayed in order of favourability, with the question ranked most 'favourable' at the top.", {offsetX: 1, offsetY: 55, sizeX: sizes.pageWidth-2, align: "left"});
    w(doc, "These highlight the areas where the team work best and this should be celebrated.", {offsetX: 1, offsetY: 62.5, sizeX: sizes.pageWidth-2, align: "left"});

    t(doc, questions[fav[0]], {offsetY: 75+10*0, sizeX: sizes.pageWidth, align: "left"});
    t(doc, questions[fav[1]], {offsetY: 75+10*1, sizeX: sizes.pageWidth, align: "left"});
    t(doc, questions[fav[2]], {offsetY: 75+10*2, sizeX: sizes.pageWidth, align: "left"});
    t(doc, questions[fav[3]], {offsetY: 75+10*3, sizeX: sizes.pageWidth, align: "left"});
    t(doc, questions[fav[4]], {offsetY: 75+10*4, sizeX: sizes.pageWidth, align: "left"});
    t(doc, questions[fav[5]], {offsetY: 75+10*5, sizeX: sizes.pageWidth, align: "left"});
    t(doc, questions[fav[6]], {offsetY: 75+10*6, sizeX: sizes.pageWidth, align: "left"});


    // Middle 7

    doc.addPage("a4", "l");

    h2(doc, "Top, Middle, and Bottom", {offsetX: 20, offsetY: 7.5});
    img(doc, "/assets/images/icons/brands/performance-edge.png", 15, 10, 15, 15);

    doc.setDrawColor(colours.auxiliary);
    doc.setLineWidth(2);
    doc.line(sizes.marginX, sizes.marginY+15, doc.internal.pageSize.width-sizes.marginX, sizes.marginY+15);

    h3(doc, "Middle 7: Areas of Uncertainty", {offsetY: 30});

    doc.setFillColor(colours.neutral);
    doc.rect(sizes.marginX, 47.5, sizes.pageWidth, 30, "F");
    w(doc, "Below are 7 areas of uncertainty, showing the areas ranked most 'neutral' by participants.", {offsetX: 1, offsetY: 40, sizeX: sizes.pageWidth-2, align: "left"});
    w(doc, "They are the questions ranked with the highest number of 'undecided' ratings only.", {offsetX: 1, offsetY: 47.5, sizeX: sizes.pageWidth-2, align: "left"});
    w(doc, "All areas are displayed in order of uncertainty, with the question ranked most 'neutral' at the top.", {offsetX: 1, offsetY: 55, sizeX: sizes.pageWidth-2, align: "left"});
    w(doc, "These highlight the areas where the team are sitting on the fence.", {offsetX: 1, offsetY: 62.5, sizeX: sizes.pageWidth-2, align: "left"});

    t(doc, questions[neu[0]], {offsetY: 75+10*0, sizeX: sizes.pageWidth, align: "left"});
    t(doc, questions[neu[1]], {offsetY: 75+10*1, sizeX: sizes.pageWidth, align: "left"});
    t(doc, questions[neu[2]], {offsetY: 75+10*2, sizeX: sizes.pageWidth, align: "left"});
    t(doc, questions[neu[3]], {offsetY: 75+10*3, sizeX: sizes.pageWidth, align: "left"});
    t(doc, questions[neu[4]], {offsetY: 75+10*4, sizeX: sizes.pageWidth, align: "left"});
    t(doc, questions[neu[5]], {offsetY: 75+10*5, sizeX: sizes.pageWidth, align: "left"});
    t(doc, questions[neu[6]], {offsetY: 75+10*6, sizeX: sizes.pageWidth, align: "left"});

    // Bottom 7

    doc.addPage("a4", "l");

    h2(doc, "Top, Middle, and Bottom", {offsetX: 20, offsetY: 7.5});
    img(doc, "/assets/images/icons/brands/performance-edge.png", 15, 10, 15, 15);

    doc.setDrawColor(colours.auxiliary);
    doc.setLineWidth(2);
    doc.line(sizes.marginX, sizes.marginY+15, doc.internal.pageSize.width-sizes.marginX, sizes.marginY+15);

    h3(doc, "Bottom 7: Opportunities for Development", {offsetY: 30});

    doc.setFillColor(colours.unfavourable);
    doc.rect(sizes.marginX, 47.5, sizes.pageWidth, 30, "F");
    w(doc, "Below are 7 opportunities for development, showing the areas ranked most 'unfavourable' by participants.", {offsetX: 1, offsetY: 40, sizeX: sizes.pageWidth-2, align: "left"});
    w(doc, "They are the questions ranked with the highest number of 'strongly disagree' and 'disagree' ratings combined.", {offsetX: 1, offsetY: 47.5, sizeX: sizes.pageWidth-2, align: "left"});
    w(doc, "All areas are displayed in order of unfavourability, with the question ranked most 'unfavourable' at the top.", {offsetX: 1, offsetY: 55, sizeX: sizes.pageWidth-2, align: "left"});
    w(doc, "These highlight the areas where the team requires the most work.", {offsetX: 1, offsetY: 62.5, sizeX: sizes.pageWidth-2, align: "left"});

    t(doc, questions[unf[0]], {offsetY: 75+10*0, sizeX: sizes.pageWidth, align: "left"});
    t(doc, questions[unf[1]], {offsetY: 75+10*1, sizeX: sizes.pageWidth, align: "left"});
    t(doc, questions[unf[2]], {offsetY: 75+10*2, sizeX: sizes.pageWidth, align: "left"});
    t(doc, questions[unf[3]], {offsetY: 75+10*3, sizeX: sizes.pageWidth, align: "left"});
    t(doc, questions[unf[4]], {offsetY: 75+10*4, sizeX: sizes.pageWidth, align: "left"});
    t(doc, questions[unf[5]], {offsetY: 75+10*5, sizeX: sizes.pageWidth, align: "left"});
    t(doc, questions[unf[6]], {offsetY: 75+10*6, sizeX: sizes.pageWidth, align: "left"});
    
}