// Imports libraries
import React from "react";
import "../styles/style.css";
import { Outlet } from "react-router-dom";
import { Toaster } from "react-hot-toast";


// Accesses page template
export default
function Layout
()
{

    return (
        <React.Fragment>
            <Toaster />
            <Outlet />
        </React.Fragment>
    );
    
}