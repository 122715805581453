// Imports react
import React from "react";
import ReactDOM from "react-dom/client";


// Imports controller
import Router from "./controller/router";


// Imports firebase
import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getAuth, setPersistence, browserSessionPersistence } from "@firebase/auth";
import { getFunctions } from 'firebase/functions';


// Initialises backend
const application = initializeApp({
    apiKey: "AIzaSyCaep5UGEwzF8UU4skC1zAs8z_GNG-iYRQ",
    authDomain: "creating-the-team-edge.firebaseapp.com",
    projectId: "creating-the-team-edge",
    storageBucket: "creating-the-team-edge.appspot.com",
    messagingSenderId: "677846269371",
    appId: "1:677846269371:web:1adf22c772acfd21d5d89e",
    measurementId: "G-NF9E52NCEP",
});


// Activates services
export const authentication = getAuth(application);
export const database = getFirestore(application);
export const functions = getFunctions(application);
setPersistence(authentication, browserSessionPersistence);


// Renders templates
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Router />);
