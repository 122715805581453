// Imports libraries
import React from "react";


// Accesses about page
export default
function NotFound
()
{

    return (
        <React.Fragment>
            <h1>Whoops!</h1>
            <p>This page does not exist.</p>
        </React.Fragment>
    );

}