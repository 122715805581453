// Imports libraries
import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { toast } from 'react-hot-toast';
import { isMobile } from 'react-device-detect';
import { Chart } from "react-google-charts";
import html2canvas from 'html2canvas';

// Imports loacles
import locale_questionnaire from "../locale/questionnaire.json";
import locale_questions from "../locale/questions.json";
import locale_elements from "../locale/characteristics.json";

// Imports components
import Row from "../components/atoms/row";
import Card from "../components/atoms/card";
import Column from "../components/atoms/column";
import Spacer from "../components/atoms/spacer";
import Banner from "../components/molecules/banner";
import Top from "../components/organisms/top";
import Aspect from "../components/organisms/aspect";
import Bottom from "../components/organisms/bottom";

import generateReport from "./mini";

import { doc, collection, addDoc } from "firebase/firestore";
import { database as db } from "../../index";
import { p } from "./report";

export default
function Trial
(props)
{
    const navigate = useNavigate();

    const [getIndex, setIndex] = useState(0);
    const [getAnswers, setAnswers] = useState([0, 0, 0, 0, 0, 0, 0]);
    const [updateQuestions, setUpdateQuestions] = useState(0);
    const [getFullName, setFullName] = useState("");
    const [getCompanyName, setCompanyName] = useState("");
    const [getEmailAddress, setEmailAddress] = useState("");
    const [getResults, setResults] = useState(false);
    const [getTop, setTop] = useState([]);
    const [getBottom, setBottom] = useState([]);
    const [getMiddle, setMiddle] = useState([]);
    const [getSummary, setSummary] = useState([]);

    const responses = ["Not Answered", "Strongly Disagree", "Disagree", "Undecided", "Agree", "Strongly Agree"];

    const questions = [
        "My team has a clear and compelling purpose, with objectives aligned to that purpose.",
        "My team has a unique character and personality that the team is proud to identify with.",
        "Our values and beliefs shine through in all my team's work projects.",
        "My team understands each others' personal preferences and working styles in depth.",
        "The relationships in my team are built on trust - we are open and honest with each other.",
        "My team is known for the effectiveness of its meetings - they are thought-provoking, engaging, and always result in a clear set of actions.",
        "My team frequently gets together to identify new ways to grow and sustain its development.",
    ];

    const permittedCharacters = /^[ a-zA-Z0-9!()#$£"%&'+,-./:;<>?@_~]{0,256}$/;

    const sections = [
        "Purpose",
        "Identity",
        "Values and Belief",
        "Awareness",
        "Relatedness",
        "Ways of Working",
        "Transformation"
    ]

    function zfill(number, width) {
        const str = String(number);
        return str.length >= width ? str : new Array(width - str.length + 1).join('0') + str;
    }

    const refScreenSelect = useRef(null);
    const captureResults = async () => {
        const screenSelection = refScreenSelect.current;
        if (!screenSelection) return;
        const canvas = await html2canvas(screenSelection);
        const urlScreenshot = canvas.toDataURL("image/png");
        const downloadLink = document.createElement("a");
        downloadLink.href = urlScreenshot;
        const today = new Date();
        downloadLink.download = `CTE_MINI_360_BREAKDOWN-${zfill(today.getFullYear(), 4)}_${zfill(today.getMonth()+1, 2)}_${zfill(today.getDate(), 2)}-${getFullName.trim().toUpperCase()}_FROM_${getCompanyName.trim().toUpperCase()}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

    }

    const changeResponse = (index, value) => {
        const answers = getAnswers;
        const current = getIndex;
        answers[index] = value;
        setAnswers(answers);
        if (index === current) {
            setIndex(current + 1);
        }
        setUpdateQuestions(updateQuestions + 1);
    }

    const handleKeyDown = (event) => {
        const input = event.key;
        if (permittedCharacters.test(input) && event.keyCode !== 13) return;
        event.preventDefault(); // Prevent the character from being entered
        toast.error("This character is not allowed. Please try again.");
    };

    const save = () => {
        if (!/^[ a-zA-Z0-9!()#$£"%&'+,-./:;<>?@_~]{1,128}$/.test(getFullName)) {
            toast.error(`Your full name must be 1 to 128 characters, including letters, numbers, and some symbols. Please try again.`)
            return;
        }
        if (!/^[ a-zA-Z0-9!()#$£"%&'+,-./:;<>?@_~]{1,128}$/.test(getCompanyName)) {
            toast.error(`Your team name must be 1 to 128 characters, including letters, numbers, and some symbols. Please try again.`)
            return;
        }
        toast.promise(addDoc(collection(db, `Trial`), {
            full_name: getFullName,
            team_name: getCompanyName,
            q1: getAnswers[0],
            q2: getAnswers[1],
            q3: getAnswers[2],
            q4: getAnswers[3],
            q5: getAnswers[4],
            q6: getAnswers[5],
            q7: getAnswers[6]
        }, {merge: false}).then(() => {
            setTop(getAnswers.map((value) => value >= 4));
            setMiddle(getAnswers.map((value) => value === 3));
            setBottom(getAnswers.map((value) => value <= 2));
            const stuff = [0, 0, 0, 0, 0];
            getAnswers.forEach((value) => ++stuff[value-1]);
            setSummary([
                ["Favourability", "Frequency"],
                ["Unfavourable", stuff[0] + stuff[1]],
                ["Neutral", stuff[2]],
                ["Favourable", stuff[3] + stuff[4]],
            ]);
            setResults(true);
        }), {
            loading: `Saving your responses...`,
            success: `Successfully saved your responses. Here are your results...`,
            error: `We were unable to save your responses. Please check your internet connection and try again.`
        })
    }

    const refSave = useRef(null);
    useEffect(() => {
        if (getIndex < 7 && getIndex > 0)
            document.getElementById(`q${getIndex+1}`).scrollIntoView({ top: 20, behavior: 'smooth' });
        else if (getIndex === 7)
            refSave.current.scrollIntoView({ top: 20, behavior: "smooth" });
    }, [updateQuestions,]);

    const refResults = useRef(null);
    useEffect(() => {
        if (!getResults) return;
        const results = refResults.current;
        results.scrollIntoView({ top: 20, behavior: 'smooth' });
    }, [getResults])

    return (
        <React.Fragment>
            <Top title="Mini 360 Questionnaire" hero="By 'Creating the Team Edge'">
            </Top>

            <main class="container">

                <Spacer />

                <section><Banner theme="accent"><Column size="100" side="left">
                    <h2>Welcome</h2>
                    <h3>This is a mini demonstration of a Team 360 Questionnaire with 7 sample questions.</h3>
                    {(!isMobile) && <h3>We will collate and visualise your responses into a free Mini 360 Report.</h3>}
                    <hr class="container" />
                    <p>By responding to the questions, you agree implicitly to our Privacy Policy. Please contact us at info@performance-edge.co.uk if you would like to delete this data.</p>
                    <a target="_blank" href="/assets/files/Privacy Policy - Creating the Team Edge.pdf"><button class="primary">Privacy Policy</button></a>
                </Column></Banner></section>

                <Spacer />

                <Row><Column size="100" side="left">
                    <details class="info" id="categories">
                        <summary>Instructions</summary>
                        <hr class="container" />
                        <ul class="instructions">
                            <li><h3>This Mini 360 Questionnaire aims to analyse the performance of your team compared to our 'Creating the Team Edge' model.</h3></li>
                            <li><h3>Your responses will provide a valuable insight into areas your team should celebrate and areas your team could develop.</h3></li>
                            <li><h3>At the end of this Mini 360 Questionnaire, there will be a breakdown of your responses.</h3></li>
                            <li><h3>There are 7 questions covering the 7 characteristics of high-performing teams from our 'Creating the Team Edge' model.</h3></li>
                            <li><h3>For each question, indicate the extent to which you agree the team fulfills the statement.</h3></li>
                            <li><h3>Please make sure you have a strong, stable, and secure connection before you start.</h3></li>
                            <li><h3>This Mini 360 Questionnaire should take approximately 5 minutes to complete.</h3></li>
                            <li><h3>Your responses will be saved in our system after all questions have been completed.</h3></li>
                            <li><h3>Any saved responses may be used anonymously to enhance user experience and generate statistics.</h3></li>
                            <li><h3>For data integrity purposes, your responses cannot be changed once they have been saved in our system.</h3></li>
                        </ul>
                    </details>
                </Column></Row>

                {updateQuestions > -1 && questions.map((question, index) => {

                    if (getIndex < index) return;

                    return (<Row><Column size="100" side="left"><Banner theme="accent">
                    <p id={`q${index+1}`}>Question {index+1} of 7</p>
                    <h2 class="no-space accent">{sections[index]}</h2>
                    <h3>"{question}"</h3>
                    <p class="accent">Latest Response: {responses[getAnswers[index]]}</p>
                    <hr class="container" /><Spacer />
                    <div class="option-buttons">
                        <img draggable="false" onClick={() => changeResponse(index, 1)} src="/assets/images/icons/team-360/questionnaire/strongly-disagree.png" alt="Strongly Disagree" />
                        <img draggable="false" onClick={() => changeResponse(index, 2)} src="/assets/images/icons/team-360/questionnaire/disagree.png" alt="Disagree" />
                        <img draggable="false" onClick={() => changeResponse(index, 3)} src="/assets/images/icons/team-360/questionnaire/undecided.png" alt="Undecided" />
                        <img draggable="false" onClick={() => changeResponse(index, 4)} src="/assets/images/icons/team-360/questionnaire/agree.png" alt="Agree" />
                        <img draggable="false" onClick={() => changeResponse(index, 5)} src="/assets/images/icons/team-360/questionnaire/strongly-agree.png" alt="Strongly Agree" />
                    </div>
                    </Banner></Column><Spacer /></Row>);

                })}

                {(getIndex >= 7) &&
                    <React.Fragment>
                    <Spacer /><hr class="container" /><Spacer />

                    <Row><div class="grid-2x1" ref={refResults}>

                    <div ref={refSave} class="left-thing">
                        <Spacer />
                        <h2 class="no-space accent">Final Steps</h2>

                        <Spacer /><hr class="container" /><Spacer />
                        <p class="accent">Enter your full name and your team name to see a breakdown of your responses.</p>
                        <p class="accent">Your full name and team name must only contain letters, numbers, and spaces.</p>
                        <p class="accent">By clicking 'Save', you actively agree to the 'Instructions' and 'Privacy Policy'.</p>
                        <Spacer /><hr class="container" /><Spacer />
                        <Spacer />
                        <label for="company_name">Your Team Name</label>
                        <input class="trial-entry" onKeyDown={(event) => handleKeyDown(event)} type="text" id="company_name" placeholder="Please type here..." value={getCompanyName} onChange={(event) => setCompanyName(event.target.value)} />

                        <Spacer />
                        <label for="full_name">Your Full Name</label>
                        <input class="trial-entry" onKeyDown={(event) => handleKeyDown(event)} type="text" id="full_name" placeholder="Please type here..." value={getFullName} onChange={(event) => setFullName(event.target.value)} />

                        <Spacer />
                        <button class="primary" onClick={save}>Save</button>
                    
                    </div>
                    
                    {getResults &&
                        <div style={{textAlign: "center"}} class="right-thing">
                            <Spacer />

                            <div ref={refScreenSelect} style={{backgroundColor: "#FFF", padding: "16px"}}>

                            <img src="/assets/images/icons/brands/performance-edge-logo.png" alt="Performance Edge Logo" height="100px" class="logo" />
                            <Spacer />

                            <h2 class="no-space primary">Mini 360<br />Team Breakdown</h2>
                            <p class="no-space primary"><a class="no-space primary" href="https://tinyurl.com/ctemini360">https://tinyurl.com/ctemini360</a></p>

                            <div style={{backgroundColor: "var(--primary)"}}>
                                <br /><br />
                                <h3 class="no-space primary">Summary of Responses</h3>
                                <Chart
                                    chartType="PieChart"
                                    data={getSummary}
                                    options={{
                                        is3D: true,
                                        colors: ["#E3072A", "#ABABAB", "#358BB7"],
                                        chartArea: {
                                            top: 0
                                        },
                                        legend: {
                                            position: "bottom",
                                            textStyle: {
                                                fontSize: 9
                                            }
                                        },
                                    }}
                                    width={"100%"}
                                    height={"250px"}
                                />
                            </div>
                            
                            <div style={{borderRadius: "1rem", color: "#FFF", backgroundColor: "#358BB7", padding: "1rem", paddingBottom: "1rem", marginBlock: "1rem"}}>
                                <h3 style={{color: "#FFF"}} class="no-space">Top Characteristics</h3>
                                {getTop.every(value => !value) && <p class="no-space">No Characteristics</p>}
                                {getTop[0] && <p class="no-space">Purpose</p>}
                                {getTop[1] && <p class="no-space">Identity</p>}
                                {getTop[2] && <p class="no-space">Values and Beliefs</p>}
                                {getTop[3] && <p class="no-space">Awareness</p>}
                                {getTop[4] && <p class="no-space">Relatedness</p>}
                                {getTop[5] && <p class="no-space">Ways of Working</p>}
                                {getTop[6] && <p class="no-space">Transformation</p>}
                            </div>
                            
                            <div style={{borderRadius: "1rem", color: "#FFF", backgroundColor: "#ABABAB", padding: "1rem", paddingBottom: "1rem", marginBlock: "1rem"}}>
                                <h3 style={{color: "#FFF"}} class="no-space">Middle Characteristics</h3>
                                {getMiddle.every(value => !value) && <p class="no-space">No Characteristics</p>}
                                {getMiddle[0] && <p class="no-space">Purpose</p>}
                                {getMiddle[1] && <p class="no-space">Identity</p>}
                                {getMiddle[2] && <p class="no-space">Values and Beliefs</p>}
                                {getMiddle[3] && <p class="no-space">Awareness</p>}
                                {getMiddle[4] && <p class="no-space">Relatedness</p>}
                                {getMiddle[5] && <p class="no-space">Ways of Working</p>}
                                {getMiddle[6] && <p class="no-space">Transformation</p>}
                            </div>

                            <div style={{borderRadius: "1rem", color: "#FFF", backgroundColor: "#E3072A", padding: "1rem", paddingBottom: "1rem", marginBlock: "1rem"}}>
                                <h3 style={{color: "#FFF"}} class="no-space">Bottom Characteristics</h3>
                                {getBottom.every(value => !value) && <p class="no-space">No Characteristics</p>}
                                {getBottom[0] && <p class="no-space">Purpose</p>}
                                {getBottom[1] && <p class="no-space">Identity</p>}
                                {getBottom[2] && <p class="no-space">Values and Beliefs</p>}
                                {getBottom[3] && <p class="no-space">Awareness</p>}
                                {getBottom[4] && <p class="no-space">Relatedness</p>}
                                {getBottom[5] && <p class="no-space">Ways of Working</p>}
                                {getBottom[6] && <p class="no-space">Transformation</p>}
                            </div>

                            </div>

                            <Spacer />
                            <button class="accent" onClick={captureResults}>Download</button>

                        </div>
                    }
                    
                    </div><Spacer /></Row>

                    </React.Fragment>
                }

            </main>

            <Bottom />
        </React.Fragment>
    );
}