import {h2, h3, p, s, w, img, sizes, colours, k, t} from "../report";

export default
async function Comments (doc, teamStrengths, areasToDevelop, ideasForAction) {

    let area;
    let offset = 0;

    if (teamStrengths.length < 1) {
        offset = 0;
        doc.addPage("a4", "l");

        h2(doc, "Summary Comments", {offsetX: 20, offsetY: 7.5});
        img(doc, "/assets/images/icons/brands/performance-edge.png", 15, 10, 15, 15);

        doc.setDrawColor(colours.auxiliary);
        doc.setLineWidth(2);
        doc.line(sizes.marginX, sizes.marginY+15, doc.internal.pageSize.width-sizes.marginX, sizes.marginY+15);

        h3(doc, "Team Strengths - things we should continue doing", {offsetY: 30});

        doc.setFillColor("#EEEEEE");
        doc.setDrawColor(colours.neutral);
        doc.setLineWidth(0.25); 
        doc.rect(sizes.marginX, 40+offset+8.5, sizes.pageWidth, 21, "FD");
        t(doc, "No participants have provided team strengths.", {offsetX: 2, offsetY: 40+offset, sizeX: sizes.pageWidth-2, align: "left"});

    } else
    for (let count = 0; count < teamStrengths.length; count++) {
        area = teamStrengths[count];
        if (count % 6 == 0) {
            offset = 0;
            doc.addPage("a4", "l");

            h2(doc, "Summary Comments", {offsetX: 20, offsetY: 7.5});
            img(doc, "/assets/images/icons/brands/performance-edge.png", 15, 10, 15, 15);

            doc.setDrawColor(colours.auxiliary);
            doc.setLineWidth(2);
            doc.line(sizes.marginX, sizes.marginY+15, doc.internal.pageSize.width-sizes.marginX, sizes.marginY+15);

            if (count < 6) {
                h3(doc, "Team Strengths - things we should continue doing", {offsetY: 30});
            } else {
                h3(doc, "Team Strengths - things we should continue doing, continued", {offsetY: 30});
            }
        }
        doc.setFillColor("#FFFFFF");
        if (count % 2 === 0) doc.setFillColor("#EEEEEE");
        doc.setDrawColor(colours.neutral);
        doc.setLineWidth(0.25); 
        doc.rect(sizes.marginX, 40+offset+8.5, sizes.pageWidth, 21, "FD");
        t(doc, area, {offsetX: 2, offsetY: 40+offset, sizeX: sizes.pageWidth-2, align: "left"});
        offset = offset + 22.5;
    }

    if (areasToDevelop.length < 1) {
        doc.addPage("a4", "l");

        h2(doc, "Summary Comments", {offsetX: 20, offsetY: 7.5});
        img(doc, "/assets/images/icons/brands/performance-edge.png", 15, 10, 15, 15);

        doc.setDrawColor(colours.auxiliary);
        doc.setLineWidth(2);
        doc.line(sizes.marginX, sizes.marginY+15, doc.internal.pageSize.width-sizes.marginX, sizes.marginY+15);

        h3(doc, "Areas to Develop - things we should stop doing", {offsetY: 30});

        doc.setFillColor("#EEEEEE");
        doc.setDrawColor(colours.neutral);
        doc.setLineWidth(0.25); 
        doc.rect(sizes.marginX, 40+offset+8.5, sizes.pageWidth, 21, "FD");
        t(doc, "No participants have provided areas to develop.", {offsetX: 2, offsetY: 40+offset, sizeX: sizes.pageWidth-4, align: "left"});

    } else
    for (let count = 0; count < areasToDevelop.length; count++) {
        area = areasToDevelop[count];
        if (count % 6 == 0) {
            offset = 0;
            doc.addPage("a4", "l");

            h2(doc, "Summary Comments", {offsetX: 20, offsetY: 7.5});
            img(doc, "/assets/images/icons/brands/performance-edge.png", 15, 10, 15, 15);

            doc.setDrawColor(colours.auxiliary);
            doc.setLineWidth(2);
            doc.line(sizes.marginX, sizes.marginY+15, doc.internal.pageSize.width-sizes.marginX, sizes.marginY+15);

            if (count < 6) {
                h3(doc, "Areas to Develop - things we should stop doing", {offsetY: 30});
            } else {
                h3(doc, "Areas to Develop - things we should stop doing, continued", {offsetY: 30});
            }
        }
        doc.setFillColor("#FFFFFF");
        if (count % 2 === 0) doc.setFillColor("#EEEEEE");
        doc.setDrawColor(colours.neutral);
        doc.setLineWidth(0.25); 
        doc.rect(sizes.marginX, 40+offset+8.5, sizes.pageWidth, 21, "FD");
        t(doc, area, {offsetX: 2, offsetY: 40+offset, sizeX: sizes.pageWidth-2, align: "left"});
        offset = offset + 22.5;
    }

    if (ideasForAction.length < 1) {
        doc.addPage("a4", "l");

        h2(doc, "Summary Comments", {offsetX: 20, offsetY: 7.5});
        img(doc, "/assets/images/icons/brands/performance-edge.png", 15, 10, 15, 15);

        doc.setDrawColor(colours.auxiliary);
        doc.setLineWidth(2);
        doc.line(sizes.marginX, sizes.marginY+15, doc.internal.pageSize.width-sizes.marginX, sizes.marginY+15);

        h3(doc, "Ideas for Action - things we could start doing", {offsetY: 30});

        doc.setFillColor("#EEEEEE");
        doc.setDrawColor(colours.neutral);
        doc.setLineWidth(0.25); 
        doc.rect(sizes.marginX, 40+offset+8.5, sizes.pageWidth, 21, "FD");
        t(doc, "No participants have provided ideas for action.", {offsetX: 2, offsetY: 40+offset, sizeX: sizes.pageWidth-4, align: "left"});

    } else
    for (let count = 0; count < ideasForAction.length; count++) {
        area = ideasForAction[count];
        if (count % 6 == 0) {
            offset = 0;
            doc.addPage("a4", "l");

            h2(doc, "Summary Comments", {offsetX: 20, offsetY: 7.5});
            img(doc, "/assets/images/icons/brands/performance-edge.png", 15, 10, 15, 15);

            doc.setDrawColor(colours.auxiliary);
            doc.setLineWidth(2);
            doc.line(sizes.marginX, sizes.marginY+15, doc.internal.pageSize.width-sizes.marginX, sizes.marginY+15);

            if (count < 6) {
                h3(doc, "Ideas for Action - things we could start doing", {offsetY: 30});
            } else {
                h3(doc, "Ideas for Action - things we could start doing, continued", {offsetY: 30});
            }
        }
        doc.setFillColor("#FFFFFF");
        if (count % 2 === 0) doc.setFillColor("#EEEEEE");
        doc.setDrawColor(colours.neutral);
        doc.setLineWidth(0.25); 
        doc.rect(sizes.marginX, 40+offset+8.5, sizes.pageWidth, 21, "FD");
        t(doc, area, {offsetX: 2, offsetY: 40+offset, sizeX: sizes.pageWidth-4, align: "left"});
        offset = offset + 22.5;
    }

}