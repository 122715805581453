// Imports libraries
import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

// TRIAL BUTTON <a href="/trial"><button class="primary space" id="join">{text_home["page"]["options"]["trial"]}</button></a>

// Imports loacles
import locale_home from "../locale/home.json";
import locale_characteristics from "../locale/characteristics.json";

// Imports components
import Row from "../components/atoms/row";
import Card from "../components/atoms/card";
import Column from "../components/atoms/column";
import Spacer from "../components/atoms/spacer";
import Banner from "../components/molecules/banner";
import Top from "../components/organisms/top";
import Aspect from "../components/organisms/aspect";
import Bottom from "../components/organisms/bottom";

// Accesses home page
export default
function Thanks
(props)
{

    const navigate = useNavigate();

    const text_home = JSON.parse(JSON.stringify(locale_home));
    const text_characteristics = JSON.parse(JSON.stringify(locale_characteristics));

    return (
        <React.Fragment>
            
            <Top title="Thank you" hero="You successfully completed your Team 360 Questionnaire">
                <Row>
                    <a href="/"><button class="primary space" id="home">Go Home</button></a>
                </Row>
            </Top>

            <main>

                <Spacer />

                <section id="book" class="container">

                    <Row><Column size="100" side="left">
                        <h2>Explore our team coaching framework</h2>
                        <p class="description">{text_home["book"]["meta"]["description"]}</p>
                    </Column></Row>

                    <Row>

                        <Column size="25" side="left">
                            <img class="infographic left" src={text_home["book"]["essentials"]["image"]} alt={text_home["book"]["essentials"]["title"]} draggable={false} />
                        </Column>

                        <Column size="75" side="right">

                            <Row>
                                <p>{text_home["book"]["information"]["1"]}</p>
                                <p>{text_home["book"]["information"]["2"]}</p>
                                <p>{text_home["book"]["information"]["3"]}</p>
                                <Row><center>

                                <Column size="25" side="left"><Spacer /><Card theme="auxiliary">
                                    <img draggable={false} class="icon left" src={text_home["book"]["purchase"][1]["icon"]} alt={text_home["book"]["purchase"][1]["location"]} />
                                    <h3>{text_home["book"]["purchase"][1]["location"]}</h3>
                                    <p>{text_home["book"]["purchase"][1]["description"]}</p>
                                    <a href={text_home["book"]["purchase"][1]["link"]}><button class="alternative">{text_home["book"]["purchase"][1]["action"]}</button></a>
                                </Card><Spacer /></Column>

                                <Column size="25" side="left"><Spacer /><Card theme="auxiliary">
                                    <img draggable={false} class="icon left" src={text_home["book"]["purchase"][2]["icon"]} alt={text_home["book"]["purchase"][2]["location"]} />
                                    <h3>{text_home["book"]["purchase"][2]["location"]}</h3>
                                    <p>{text_home["book"]["purchase"][2]["description"]}</p>
                                    <a href={text_home["book"]["purchase"][2]["link"]}><button class="alternative">{text_home["book"]["purchase"][2]["action"]}</button></a>
                                </Card><Spacer /></Column>

                                <Column size="25" side="left"><Spacer /><Card theme="auxiliary">
                                    <img draggable={false} class="icon left" src={text_home["book"]["purchase"][3]["icon"]} alt={text_home["book"]["purchase"][3]["location"]} />
                                    <h3>{text_home["book"]["purchase"][3]["location"]}</h3>
                                    <p>{text_home["book"]["purchase"][3]["description"]}</p>
                                    <a href={text_home["book"]["purchase"][3]["link"]}><button class="alternative">{text_home["book"]["purchase"][3]["action"]}</button></a>
                                </Card><Spacer /></Column>

                                <Column size="25" side="left"><Spacer /><Card theme="auxiliary">
                                    <img draggable={false} class="icon left" src={text_home["book"]["purchase"][4]["icon"]} alt={text_home["book"]["purchase"][4]["location"]} />
                                    <h3>{text_home["book"]["purchase"][4]["location"]}</h3>
                                    <p>{text_home["book"]["purchase"][4]["description"]}</p>
                                    <a href={text_home["book"]["purchase"][4]["link"]}><button class="alternative">{text_home["book"]["purchase"][4]["action"]}</button></a>
                                </Card><Spacer /></Column>

                            </center></Row>
                            </Row>

                        </Column>
                    
                    </Row>

                    <Spacer />

                </section>

                <section id="programmes" class="container"><Banner theme="accent"><Column size="100" side="left">
                    <h2>{text_home["banners"]["1"]["heading"]}</h2>
                    <p class="description">{text_home["banners"]["1"]["description"]}</p>
                    <Spacer />
                    <a href={text_home["banners"]["1"]["link"]}><button class="primary">{text_home["banners"]["1"]["action"]}</button></a>
                </Column></Banner></section>

                <section id="characteristics" class="container">

                    <Spacer />

                    <Row>
                        <h2>Discover our smart 360 diagnostic tool</h2>
                        <p class="description">{text_home["introduction"]["meta"]["description"]}</p>
                        <br />
                        <p class="description">{text_home["characteristics"]["meta"]["description"]}</p>
                        <br /><br />
                    </Row>

                    <Row>
                        <Aspect
                        icon={text_characteristics["1"]["icon"]}
                        alt={`${text_characteristics["1"]["name"]} icon`}
                        heading={`1. ${text_characteristics["1"]["name"]}`}
                        description={text_characteristics["1"]["description"]}
                        side="left"
                        /><Aspect
                        icon={text_characteristics["2"]["icon"]}
                        alt={`${text_characteristics["2"]["name"]} icon`}
                        heading={`2. ${text_characteristics["2"]["name"]}`}
                        description={text_characteristics["2"]["description"]}
                        side="right"
                        />
                    </Row>

                    <Row>
                        <Aspect
                        icon={text_characteristics["3"]["icon"]}
                        alt={`${text_characteristics["3"]["name"]} icon`}
                        heading={`3. ${text_characteristics["3"]["name"]}`}
                        description={text_characteristics["3"]["description"]}
                        side="left"
                        /><Aspect
                        icon={text_characteristics["4"]["icon"]}
                        alt={`${text_characteristics["4"]["name"]} icon`}
                        heading={`4. ${text_characteristics["4"]["name"]}`}
                        description={text_characteristics["4"]["description"]}
                        side="right"
                        />
                    </Row>

                    <Row>
                        <Aspect
                        icon={text_characteristics["5"]["icon"]}
                        alt={`${text_characteristics["5"]["name"]} icon`}
                        heading={`5. ${text_characteristics["5"]["name"]}`}
                        description={text_characteristics["5"]["description"]}
                        side="left"
                        /><Aspect
                        icon={text_characteristics["6"]["icon"]}
                        alt={`${text_characteristics["6"]["name"]} icon`}
                        heading={`6. ${text_characteristics["6"]["name"]}`}
                        description={text_characteristics["6"]["description"]}
                        side="right"
                        />
                    </Row>

                    <Row>
                        <Aspect
                        icon={text_characteristics["7"]["icon"]}
                        alt={`${text_characteristics["7"]["name"]} icon`}
                        heading={`7. ${text_characteristics["7"]["name"]}`}
                        description={text_characteristics["7"]["description"]}
                        side="left"
                        />
                    </Row>

                    <Spacer />

                </section>

                <Spacer />

            </main>

            <Bottom />

        </React.Fragment>
    );

}