import {h2, s, img, sizes, colours} from "../report";

export default
async function Introduction (doc) {
    doc.addPage("a4", "l");

    h2(doc, "Introduction", {offsetX: 20, offsetY: 7.5});
    img(doc, "/assets/images/icons/brands/performance-edge.png", 15, 10, 15, 15);

    doc.setDrawColor(colours.auxiliary);
    doc.setLineWidth(2);
    doc.line(sizes.marginX, sizes.marginY+15, doc.internal.pageSize.width-sizes.marginX, sizes.marginY+15);

    const width = doc.internal.pageSize.width/5*3;
    let adder = 0;
    
    let text = "The 'Creating the Team Edge Profile' (CTEP) is a diagnostic tool based on our research into what it takes to be considered a high performing business team that consistently delivers high quality results."
    s(doc, text, {offsetX: 0, offsetY: 30+adder, sizeX: width});
    adder += 2 * 5.5 + 5.5;

    text = "Collectively, at Performance Edge, we have over sixty years of experience working with teams from a wide range of sectors, from small organisations to large global corporate businesses, with board teams to teams led by a front line manager. From this valuable experience, as well as swallowing a library of books on the subject of successful teams (including Peter Hawkins, Simon Sinek, Khoi Tu, and many more), we have compiled this tool based on what we have found to be the characteristics of the most successful teams over the long term. We have all been members of many teams and, indeed, led them at a senior level with some amazing brands and, although we did not know it at the time, it has all been a part of our rich research journey that led us to here today."
    s(doc, text, {offsetX: 0, offsetY: 30+adder, sizeX: width});
    adder += 8 * 5.5 + 5.5;

    text = "Completing the 'Creating Team Edge Profile' (CTEP) is a first step for you to explore the 'Creating the Team Edge' © Team Coaching Model with your team, in order to find the best route forward on your journey to future success. The 'Creating Team Edge Profile' (CTEP) gives you an insight into how your team currently shapes up to the model and helps you to identify priorities that require immediate attention if you and your team are not only to survive, but thrive at work, both now and in the future."
    s(doc, text, {offsetX: 0, offsetY: 30+adder, sizeX: width});
    adder += 5 * 5.5 + 5.5;

    text = "Whilst all teams have natural strengths and weaknesses, the skills required to be considered a high performing team are open to be developed by any team. The challenge for teams interested in development and performance improvement is knowing what to develop, how to develop it, and when is the best time to develop it so that efforts are efficiently spent in improving the right area well."
    s(doc, text, {offsetX: 0, offsetY: 30+adder, sizeX: width});

    img(doc, "/assets/images/infographics/creating-the-team-edge-infographic.png", doc.internal.pageSize.width/5*3+17.5, doc.internal.pageSize.height/4, doc.internal.pageSize.width/5*1.5, doc.internal.pageSize.width/5*1.5*1.16494845361);

}