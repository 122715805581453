// Imports libraries
import React from "react";

// Creates custom footer
export default
function Top
(props)
{

    return (
        <React.Fragment>
            <header class="top">
                <img src="/assets/images/icons/brands/performance-edge.png" alt="Performance Edge Logo" height="175px" class="logo" />
                <h1>{props.title}</h1>
                <h3>{props.hero}</h3>
                {props.children}
            </header>
        </React.Fragment>
    );
}