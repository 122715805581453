// Imports libraries
import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

// Imports loacles
import locale_join from "../locale/join.json";

// Imports components
import Row from "../components/atoms/row";
import Column from "../components/atoms/column";
import Spacer from "../components/atoms/spacer";
import Top from "../components/organisms/top";
import Bottom from "../components/organisms/bottom";

// Imports models
import { authentication as auth } from "../../index";
import { database as db } from "../../index";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { collection, doc, setDoc } from "firebase/firestore";
import Cookies from "universal-cookie";
import { toast } from "react-hot-toast";

// Imports libraries
const CryptoJS = require("crypto-js");

// Accesses join page
export default
function Join
()
{ 

    const cookies = new Cookies();

    const text_join = JSON.parse(JSON.stringify(locale_join));
    const navigate = useNavigate();

    var count = 0;
    const max = 2;
    const min = 1;

    const [getAccreditationCode, setAccreditationCode] = useState("");
    const [getPrivacyPolicy, setPrivacyPolicy] = useState(false);
    const [getCompanyName, setCompanyName] = useState("");
    const [getFirstName, setFirstName] = useState("");
    const [getLastName, setLastName] = useState("");
    const [getEmailAddress, setEmailAddress] = useState("");
    const [getPassword, setPassword] = useState("");
    const [getRetypedPassword, setRetypedPassword] = useState("");
    const [getCount, setCount] = useState(count);
    const [getPasswordStrength, setPasswordStrength] = useState(0);

    const strength_checks = 7;

    const calculatePasswordStrength = (password) => {
        var score = 0;
        if (/[a-z]/.test(password)) score++;
        if (password.length >= 8) score++;
        if (password.length >= 12) score++;
        if (password.length >= 16) score++;
        if (/[A-Z]/.test(password)) score++;
        if (/[0-9]/.test(password)) score++;
        if (/[-!&_.]/.test(password)) score++;
        setPasswordStrength(score);
    }

    const permittedCharacters = /^[ a-zA-Z0-9!()#$£"%&'+,-./:;<>?@_~]{0,256}$/;

    const handleKeyDown = (event) => {
        const input = event.key;
        if (permittedCharacters.test(input) && event.keyCode !== 13) return;
        event.preventDefault(); // Prevent the character from being entered
        toast.error("This character is not allowed. Please try again.");
    }

    useEffect(() => {
        const textAreas = document.querySelectorAll("textarea");
        const emailInputs = document.querySelectorAll('input[type="email"]');
        const textInputs = document.querySelectorAll('input[type="text"]');
        const passwordInputs = document.querySelectorAll('input[type="password"]');
        const inputTypes = [textAreas, emailInputs, textInputs, passwordInputs];

        for (let input of inputTypes) {
            if (input.length > 0) 
                for (let text of input)
                    text.addEventListener("keydown", (event) => handleKeyDown(event))
        }

    }, []);

    const today = new Date();
    const year = today.getFullYear();
    const month = today.toLocaleString("en-GB", {month: "long"});
    const accreditationHash = String(CryptoJS.SHA512(`${month}${year}`)).substring(0, 8).toUpperCase();
    const accreditationCode = `CTE${accreditationHash}X`;
    
    const action = async () => {

        if (getAccreditationCode !== accreditationCode)
        {
            toast.error(`Your accreditation code for ${month} ${year} was invalid. Please try again.`)
            return;
        }

        if (!getPrivacyPolicy)
        {
            toast.error(`Please read and agree to our Privacy Policy to create an account.`);
            return;
        }

        if (!/^[ a-zA-Z0-9!()#$£"%&'+,-./:;<>?@_~]{1,128}$/.test(getCompanyName))
        {
            toast.error(`Your company name must be 1 to 128 characters, including letters, numbers, and some symbols. Please try again.`)
            return;
        }

        if (!/^[ a-zA-Z0-9!()#$£"%&'+,-./:;<>?@_~]{1,128}$/.test(getFirstName))
        {
            toast.error(`Your first name must be 1 to 128 characters, including letters, numbers, and some symbols. Please try again.`)
            return;
        }

        if (!/^[ a-zA-Z0-9!()#$£"%&'+,-./:;<>?@_~]{1,128}$/.test(getLastName))
        {
            toast.error(`Your last name must be 1 to 128 characters, including letters, numbers, and some symbols. Please try again.`)
            return;
        }

        if (!/^[ a-zA-Z0-9!()#$£"%&'+,-./:;<>?@_~]{1,128}$/.test(getEmailAddress) || !/^[^@]*@[^@]*$/.test(getEmailAddress))
        {
            toast.error(`Your email address must be 1 to 128 characters, including letters, numbers, and some symbols. Please try again.`)
            return;
        }

        if (getPassword.length < 6)
        {
            toast.error(`Your password must be at least 6 characters. Please try again.`)
            return;
        }

        if (getPassword !== getRetypedPassword)
        {
            toast.error(`Your retyped password does not match the original. Please try again.`)
            return;
        }

        toast.promise(
        createUserWithEmailAndPassword(auth, getEmailAddress, getPassword)
        .then((credentials) => {
            const user_id = credentials.user.uid;
            const data = {
                email_address: getEmailAddress,
                first_name: getFirstName,
                last_name: getLastName,
                company_name: getCompanyName,
                privacy_policy: getPrivacyPolicy
            }
            const settings = {
                merge: false
            }
            const coach = doc(collection(db, `Coaches`), user_id);
            setDoc(coach, data, settings)
            .catch(e => toast.error(`There was an error creating your account: ${e}`));
            navigate("/");
        }), {
            loading: "Creating your account...",
            error: "We were unable to create your account. An account with this email address may already exist. Please try again.",
            success: "Successfully created your account. You were sent to the Coach Portal."
        })

    }

    const nextStep = () => {

        var count = getCount;

        if (count === 0)
        {

            if (getAccreditationCode !== accreditationCode)
            {
                toast.error(`Your accreditation code for ${month} ${year} was invalid. Please try again.`)
                return;
            }

            if (!getPrivacyPolicy)
            {
                toast.error(`Please read and agree to our Privacy Policy to create an account.`);
                return;
            }

        }

        else if (count === 1)
        {

            if (getAccreditationCode !== accreditationCode)
            {
                toast.error(`Your accreditation code for ${month} ${year} was invalid. Please try again.`)
                return;
            }

            if (!getPrivacyPolicy)
            {
                toast.error(`Please read and agree to our Privacy Policy to create an account.`);
                return;
            }

            if (!/^[ a-zA-Z0-9!()#$£"%&'+,-./:;<>?@_~]{1,128}$/.test(getCompanyName))
            {
                toast.error(`Your company name must be 1 to 128 characters, including letters, numbers, and some symbols. Please try again.`)
                return;
            }

            if (!/^[ a-zA-Z0-9!()#$£"%&'+,-./:;<>?@_~]{1,128}$/.test(getFirstName))
            {
                toast.error(`Your first name must be 1 to 128 characters, including letters, numbers, and some symbols. Please try again.`)
                return;
            }

            if (!/^[ a-zA-Z0-9!()#$£"%&'+,-./:;<>?@_~]{1,128}$/.test(getLastName))
            {
                toast.error(`Your last name must be 1 to 128 characters, including letters, numbers, and some symbols. Please try again.`)
                return;
            }

        }

        if (count > max) return;

        var tabs = document.getElementsByClassName("tab");
        var steps = document.getElementsByClassName("step");

        var submit = document.getElementById("action");
        var next = document.getElementById("next");

        tabs[count].classList.remove("active");
        tabs[count].classList.remove("first");
        steps[count].classList.remove("active");

        count = count + 1;

        tabs[count].classList.add("active");
        steps[count].classList.add("active");

        if (count === max)
        {
            submit.classList.remove("hide");
            submit.classList.add("show");
            next.classList.add("hide");
            next.classList.remove("show");
        }

        setCount(count);

    }

    const prevStep = () => {

        var count = getCount;

        if (count < min) {
            navigate("/");
            return;
        }

        var tabs = document.getElementsByClassName("tab");
        var steps = document.getElementsByClassName("step");

        var submit = document.getElementById("action");
        var next = document.getElementById("next");

        if (count === max)
        {
            submit.classList.remove("show");
            submit.classList.add("hide");
            next.classList.add("show");
            next.classList.remove("hide");
        }

        tabs[count].classList.remove("active");
        steps[count].classList.remove("active");

        count = count - 1;

        tabs[count].classList.add("active");
        steps[count].classList.add("active");

        setCount(count);

    }

    return (
        <React.Fragment>
            
            <Top title={text_join["page"]["meta"]["title"]} hero={text_join["page"]["meta"]["hero"]} />

            <main>

                <section id="form" class="container">


                    <Row><Column size="100" side="left">

                        <Row>
                            <h2>Part {getCount+1} of 3</h2>
                        </Row>

                        <div class="first tab">

                            <Spacer />

                            <label for="accreditation_code">{text_join["form"]["1"]["accreditation_code"]}</label>
                            <input type="text" id="accreditation_code" placeholder="Please type here..." value={getAccreditationCode} onChange={(event) => setAccreditationCode(event.target.value)} />

                            <Spacer />

                            <input type="checkbox" id="privacy_policy" value={getPrivacyPolicy} onChange={(event) => setPrivacyPolicy(!getPrivacyPolicy)} />
                            <label for="privacy_policy">{text_join["form"]["1"]["privacy_policy"]["agreement"]} <Spacer /> <a href={text_join["form"]["1"]["privacy_policy"]["link"]} target="_blank"><button class="accent">{text_join["form"]["1"]["privacy_policy"]["action"]}</button></a></label>

                            <Spacer />

                        </div>

                        <div class="tab">

                            <Spacer />
                            
                            <label for="company_name">{text_join["form"]["2"]["company_name"]}</label>
                            <input type="text" id="company_name" placeholder="Please type here..." value={getCompanyName} onChange={(event) => setCompanyName(event.target.value)} />

                            <Spacer />
                            
                            <label for="first_name">{text_join["form"]["2"]["first_name"]}</label>
                            <input type="text" id="first_name" placeholder="Please type here..." value={getFirstName} onChange={(event) => setFirstName(event.target.value)} />

                            <Spacer />
                            
                            <label for="last_name">{text_join["form"]["2"]["last_name"]}</label>
                            <input type="text" id="last_name" placeholder="Please type here..." value={getLastName} onChange={(event) => setLastName(event.target.value)} />

                            <Spacer />

                        </div>

                        <div class="tab">

                            <Spacer />
                            
                            <label for="email">{text_join["form"]["3"]["email_address"]}</label>
                            <input type="email" id="email" name="email" placeholder="Please type here..." value={getEmailAddress} onChange={(event) => setEmailAddress(event.target.value.replaceAll(" ", "").replaceAll(",", "").toLowerCase())} />

                            <Spacer />
                            
                            <label for="password">{text_join["form"]["3"]["password"]}</label>
                            <input type="password" id="password" name="password" placeholder="Please type here..." value={getPassword} onChange={(event) => {
                                setPassword(event.target.value);
                                calculatePasswordStrength(event.target.value);
                            }} />

                            <Spacer />

                            <label for="password_strength">{text_join["form"]["3"]["password_strength"]}</label>
                            <meter id="password_strength" value={getPasswordStrength} min={0} optimum={Math.round(strength_checks*0.6)} max={strength_checks}></meter>
                            <label for="password_strength"> {Math.round(getPasswordStrength/strength_checks*100)}%</label>

                            <Spacer />
                            
                            <label for="retype_password">{text_join["form"]["3"]["retype_password"]}</label>
                            <input type="password" id="retype_password" placeholder="Please type here..." value={getRetypedPassword} onChange={(event) => setRetypedPassword(event.target.value)} />

                            <Spacer />

                        </div>

                        <Row>

                            <Column side="left" size="20">
                                <button class="hide accent" id="action" onClick={() => action()}>{text_join["form"]["meta"]["action"]}</button>
                                <button class="show accent" id="next" onClick={() => nextStep()}>{text_join["form"]["meta"]["next"]}</button>
                                <button class="show blend" id="prev" onClick={() => prevStep()}>{text_join["form"]["meta"]["prev"]}</button>
                            </Column>


                            <Column side="right" size="80">
                                <div class="step-container">
                                    <span class="step active"></span>
                                    <span class="step"></span>
                                    <span class="step"></span>
                                </div>
                            </Column>

                        </Row>

                    </Column></Row>

                </section>

                <Spacer />
                
            </main>

            <Bottom />
        
        </React.Fragment>
    );

}