// Imports libraries
import React from "react";
import { useNavigate } from "react-router-dom";

// Imports components
import Row from "../components/atoms/row";
import Card from "../components/atoms/card";
import Column from "../components/atoms/column";
import Spacer from "../components/atoms/spacer";
import Banner from "../components/molecules/banner";
import Top from "../components/organisms/top";
import Aspect from "../components/organisms/aspect";
import Bottom from "../components/organisms/bottom";

// Imports models
import { authentication as auth } from "../../index";
import { sendPasswordResetEmail } from "firebase/auth";
import { database as db } from "../../index";
import { collection, doc, getDoc, getDocs, deleteDoc, setDoc, addDoc, updateDoc } from "firebase/firestore";
import { deleteUser } from "firebase/auth";

// Imports locales
import locale_settings from "../locale/settings.json";
import { useState } from "react";
import { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { toast } from "react-hot-toast";

// Accesses about page
export default
function Settings
(props)
{

    const text_settings = JSON.parse(JSON.stringify(locale_settings));
    const navigate = useNavigate();


    const [getCompanyName, setCompanyName] = useState("");
    const [getFirstName, setFirstName] = useState("");
    const [getLastName, setLastName] = useState("");
    const [email_address, setEmailAddress] = useState("");
    const [getDelete, setDelete] = useState(false);
    const [id, setID] = useState("");

    const permittedCharacters = /^[ a-zA-Z0-9!()#$£"%&'+,-./:;<>?@_~]{0,256}$/;

    const handleKeyDown = (event) => {
        const input = event.key;
        if (permittedCharacters.test(input) && event.keyCode !== 13) return;
        event.preventDefault(); // Prevent the character from being entered
        toast.error("This character is not allowed. Please try again.");
    }

    useEffect(() => {
        const textAreas = document.querySelectorAll("textarea");
        const emailInputs = document.querySelectorAll('input[type="email"]');
        const textInputs = document.querySelectorAll('input[type="text"]');
        const passwordInputs = document.querySelectorAll('input[type="password"]');
        const inputTypes = [textAreas, emailInputs, textInputs, passwordInputs];

        for (let input of inputTypes) {
            if (input.length > 0) 
                for (let text of input)
                    text.addEventListener("keydown", (event) => handleKeyDown(event))
        }

    }, []);

    const [user] = useAuthState(auth);

    useEffect(() => {
        const gather = async () => {

            const cDOC = await getDoc(doc(collection(db, "Coaches"), user.uid));
            const coach = cDOC.data();

            setCompanyName(coach.company_name);
            setFirstName(coach.first_name);
            setLastName(coach.last_name);
            setEmailAddress(coach.email_address);
        
        }
        gather();
    }, [])

    const updateCoach = () => {

        if (!/^[ a-zA-Z0-9!()#$£"%&'+,-./:;<>?@_~]{1,128}$/.test(getCompanyName))
        {
            toast.error(`Your company name must be 1 to 128 characters, including letters, numbers, and some symbols. Please try again.`)
            return;
        }

        if (!/^[ a-zA-Z0-9!()#$£"%&'+,-./:;<>?@_~]{1,128}$/.test(getFirstName))
        {
            toast.error(`Your first name must be 1 to 128 characters, including letters, numbers, and some symbols. Please try again.`)
            return;
        }

        if (!/^[ a-zA-Z0-9!()#$£"%&'+,-./:;<>?@_~]{1,128}$/.test(getLastName))
        {
            toast.error(`Your last name must be 1 to 128 characters, including letters, numbers, and some symbols. Please try again.`)
            return;
        }

        const coachLOC = doc(collection(db, `Coaches`), user.uid);
        const data = {
            "company_name": getCompanyName,
            "first_name": getFirstName,
            "last_name": getLastName
        };
        toast.promise(updateDoc(coachLOC, data)
        .then(() => {
            navigate("/");
        }), {
            loading: "Updating your personal information...",
            success: "Successfully updated your personal information.",
            error: "We were unable to update your personal information. Please check your internet connection and try again."
        });
    }

    const deleteAccount = () => {
        if (!getDelete) {
            toast.error("You must indicate you understand before deleting your account. Please try again.")
            return;
        }
        toast.promise(deleteUser(auth.currentUser)
        .then(() => {
            navigate("/");
        }), {
            loading: "Deleting your account...",
            success: "Successfully deleted your account. We hope to see you again soon.",
            error: "We were unable to delete your account. Please check your internet connection and try again."
        })
    }

    return (
        <React.Fragment>

            <Top id="Hello" title={text_settings["page"]["meta"]["title"]} hero={text_settings["page"]["meta"]["hero"]}>
                <button class="accent space" id="home" onClick={() => navigate("/")}>{text_settings["page"]["meta"]["action"]}</button>
            </Top>

            <Spacer />

            <main>
                <section id="information">

                    <Row><Column size="100" side="left"><details class="container">
                        <summary>{text_settings["actions"]["title"]}</summary>
                        <hr />
                        <Spacer />
                        <label for="company_name">{text_settings["actions"]["company_name"]}</label><br />
                        <input type="text" id="company_name" placeholder="Please type here..." value={getCompanyName} onChange={(event) => setCompanyName(event.target.value)} />
                        <Spacer />
                        <label for="first_name">{text_settings["actions"]["first_name"]}</label><br />
                        <input type="text" id="first_name" placeholder="Please type here..." value={getFirstName} onChange={(event) => setFirstName(event.target.value)} />
                        <Spacer />
                        <label for="last_name">{text_settings["actions"]["last_name"]}</label><br />
                        <input type="text" id="last_name" placeholder="Please type here..." value={getLastName} onChange={(event) => setLastName(event.target.value)} />
                        <Spacer />
                        <button class="primary" onClick={() => updateCoach()}>{text_settings["actions"]["action"]}</button>
                        <a class="link accent" id="forgotten_password" onClick={() =>
                        toast.promise(sendPasswordResetEmail(auth, email_address), {
                            loading: "Sending password reset email...",
                            success: "Successfully sent a password reset email. Please check your inbox and spam folders.",
                            error: "We were unable to send a password reset email. Please check your internet connection and try again."
                        })}>{text_settings["actions"]["password"]}</a>
                    </details></Column></Row>
                    <br />
                    <Row><Column size="100" side="left">
                        <details class="container">
                            <summary>{text_settings["actions"]["delete"]["title"]}</summary>
                            <hr />
                            <h3>{text_settings["actions"]["delete"]["text"]}</h3>
                            <input type="checkbox" id="confirm_delete" value={getDelete} onChange={(event) => setDelete(!getDelete)} />
                            <label for="confirm_delete">{text_settings["actions"]["delete"]["confirm"]} <Spacer /></label>
                            
                            <button class="primary" onClick={() => deleteAccount()}>{text_settings["actions"]["delete"]["action"]}</button>
                        </details>
                    </Column></Row>

                </section>
                <br />

            </main>

            <Bottom />

        </React.Fragment>
    );

}